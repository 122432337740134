<template>
  <button class="button" :style="getColor" v-on:click="toggle">
    {{ getValue }}
  </button>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "SensorComponent",
  components: {},
  props: ["properties", "index"],
  data: function () {
    return {};
  },
  computed: {
    ...mapGetters({
      getSensorMode: "lidarStore/getSensorMode",
      getSensorValue: "lidarStore/getSensorValue",
      getSensorStatus: "lidarStore/getSensorStatus",
    }),
    getColor() {
      let color;
      let backgroundcolor;
      if (
        5 == this.getSensorStatus(this.index) ||
        9 == this.getSensorStatus(this.index) ||
        10 == this.getSensorStatus(this.index)
      ) {
        if (this.getSensorMode(this.index)) {
          color = "white";
          backgroundcolor = "black";
        } else {
          color = "#303030";
          backgroundcolor = "#606060";
        }
      } else {
        if (this.getSensorMode(this.index)) {
          color = "black";
          backgroundcolor = "black";
        } else {
          color = "#606060";
          backgroundcolor = "#606060";
        }
      }
      return "background-color: " + backgroundcolor + "; color: " + color;
    },
    getValue() {
      return this.getSensorValue(this.index);
    },
  },
  methods: {
    ...mapMutations({
      setSensorMode: "lidarStore/setSensorMode",
    }),
    toggle() {
      let val = { index: Number, mode: Number };
      val.index = this.index;
      if (this.getSensorMode(this.index)) {
        val.mode = 0;
      } else {
        val.mode = 1;
      }
      this.setSensorMode(val);
    },
  },
  mutations: {},
};
</script>
<style>
.button {
  border: 3px solid #303030;
  text-align: center;
  text-decoration: none;
  font-size: large;
  transition-duration: 0.4s;
  cursor: pointer;
  width: 25%;
  margin: 0% 0% 1% 0%;
  padding: 5px;
  border-radius: 25px;
}
</style>
