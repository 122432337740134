<template>
  <div id="app" class="container">
    <div v-if="isLoginPage">
      <Login />
    </div>
    <div v-else>
      <!--<router-link v-bind:to="getHomePath" class="nav-link">Home</router-link>-->
      <router-view />
      <SidebarMenuAkahon :isUsedVueRouter="true" :menuItems="getMenuItems" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import SidebarMenuAkahon from "@/components/Sidebar-menu-akahon.vue";
import Login from "@/views/Login";
export default {
  name: "App",
  components: { SidebarMenuAkahon, Login },
  data() {
    return {
      rootTopic: null,
      subscribeTopic: null,
      userTopic: null,
      networkTopic: null,
      lidarTopic: null,
      lidarrawTopic: null,
      configTopic: null,
      registrarTopic: null,
      remoteAccessTopic: null,
      isLoginPage: 1,
    };
  },
  mounted() {},
  watch: {
    getMqttIsLoginPageUpdated: function () {
      if (this.getMqttIsLoginPage) {
        this.setMqttIsLoginPage(0);
        this.mqttDisconnect();
        this.isLoginPage = 1;
      } else {
        this.onMqttConnect();
      }
    },
  },
  created() {
    /* 
    https://www.dudeiot.com/?name=MQTTU4E530123C5E39AEE7817EEFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF544FA2F644&pass=MQTTP5D9F905A448A5C9796098064490FF15BD4C5AB750C69DD39131C4C5E027028AC78
    https://MyToken@www.dudeiot.com
    https://user:password@www.dudeiot.com
    MyToken
    */
    // eslint-disable-next-line
    //console.log("ref:"+ window.location.href);
    // eslint-disable-next-line
    // console.log("username:"+window.location.username);
    //
    if (false == this.getMqttLoginMode) {
      this.isLoginPage = 0;
      /* Set default local credentials */
      this.setMqttUserName("bpi");
      this.setMqttPassword("bpi22");
      this.setMqttRole("bpi");
      /* -- */
      this.onMqttConnect();
    }
  },
  computed: {
    getHomePath() {
      return process.env.VUE_APP_PATH;
    },
    ...mapGetters({
      mqttInstance: "mqttStore/getMqttInstance",
      getMqttLoginMode: "mqttStore/getMqttLoginMode",
      getMqttIsInstanceConnected: "mqttStore/getMqttIsInstanceConnected",
      getMqttIsLoginPage: "mqttStore/getMqttIsLoginPage",
      getMqttIsLoginPageUpdated: "mqttStore/getMqttIsLoginPageUpdated",
      getMqttUserName: "mqttStore/getMqttUserName",
      getMqttRole: "mqttStore/getMqttRole",
      getMqttUserTopic: "mqttStore/getMqttUserTopic",
      getColorByButtonIndex: "infoMsgStore/getColorByButtonIndex",
      getScenario: "infoMsgStore/getScenario",
      getMenuItems: "infoMsgStore/getMenuItems",
    }),
  },
  methods: {
    ...mapMutations({
      setMqttUserTopic: "mqttStore/setMqttUserTopic",
      setMqttUserName: "mqttStore/setMqttUserName",
      setMqttPassword: "mqttStore/setMqttPassword",
      setMqttRole: "mqttStore/setMqttRole",
      setMqttConnectionString: "mqttStore/setMqttConnectionString",
      setMqttIsLoginPage: "mqttStore/setMqttIsLoginPage",
      setMqttLoginMode: "mqttStore/setMqttLoginMode",
      setRemoteAccessStatusMsg: "mqttStore/setRemoteAccessStatusMsg",
      setRemoteAccessInfoMsg: "mqttStore/setRemoteAccessInfoMsg",
      setInfoMsg: "infoMsgStore/setInfoMsg",
      setInfoDebugMsg: "infoMsgStore/setInfoDebugMsg",
      setWifiSignalStrength: "infoMsgStore/setWifiSignalStrength",
      setWifiName: "infoMsgStore/setWifiName",
      setNetworkMsg: "networkStore/setNetworkMsg",
      setLidarMsg: "lidarStore/setLidarMsg",
      setLidarRawMsg: "lidarStore/setLidarRawMsg",
      setConfigMsg: "configStore/setConfigMsg",
      setRegistrationStatus: "configStore/setRegistrationStatus",
    }),
    ...mapActions({
      mqttConnect: "mqttStore/mqttConnect",
      mqttDisconnect: "mqttStore/mqttDisconnect",
    }),
    cleanString(str) {
      let cleanStr = "";
      let i;
      let quote = '"';
      str = str.toString();
      /* Clean the original string */
      for (i = 0; i < str.length; i++) {
        if (0x27 == str.charCodeAt(i)) {
          cleanStr = cleanStr.concat(quote);
        } else if (8220 == str.charCodeAt(i)) {
          cleanStr = cleanStr.concat(quote);
        } else if (8221 == str.charCodeAt(i)) {
          cleanStr = cleanStr.concat(quote);
        } else {
          cleanStr = cleanStr.concat(str.charAt(i));
        }
      }
      return cleanStr;
    },
    onMqttConnect: function () {
      let ws_url;
      if (this.getMqttIsInstanceConnected) {
        this.mqttDisconnect();
        this.getMqttIsInstanceConnected = 0;
      }
      if (false == this.getMqttLoginMode) {
        ws_url = "ws://" + window.location.hostname + ":9001";
      } else {
        ws_url = "wss://" + window.location.hostname + ":8083";
      }
      //this.setInfoDebugMsg(window.location.href);
      this.setInfoDebugMsg(ws_url);
      this.setMqttConnectionString(ws_url);
      //this.setMqttConnectionString("ws://dudeiot.com:9001");
      //this.setMqttConnectionString("wss://dudeiot.com:8083");
      // this.setMqttConnectionString("ws://192.168.4.83:9001");
      //this.setMqttConnectionString("ws://10.10.4.3:9001");
      /* Create dynamic credentials */
      this.rootTopic = "SL/";
      this.userTopic = this.rootTopic + this.getMqttRole;
      this.setMqttUserTopic(this.userTopic);
      this.subscribeTopic = this.getMqttUserTopic + "/#";
      this.networkTopic = this.getMqttUserTopic + "/network";
      this.lidarTopic = this.getMqttUserTopic + "/lidar";
      this.lidarrawTopic = this.getMqttUserTopic + "/lidarraw";
      this.configTopic = this.getMqttUserTopic + "/config";
      this.registrarTopic = this.getMqttUserTopic + "/registrar/status";
      this.remoteAccessTopic = this.getMqttUserTopic + "/remoteAccess/status";
      this.remoteAccessInfoTopic = this.getMqttUserTopic + "/remoteAccess/info";
      // eslint-disable-next-line
      // console.log("userTopic:"+this.getMqttUserTopic);
      /*dispatching action from mqtt store */
      this.mqttConnect().then(
        function checkConnection() {
          if (this.getMqttIsInstanceConnected) {
            this.onMqttConnectSuccess();
          } else {
            setTimeout(checkConnection.bind(this), 1000);
          }
        }.bind(this)
      );
    },
    onMqttConnectSuccess() {
      let mqttInstance = this.mqttInstance;
      this.isLoginPage = 0;

      mqttInstance.subscribe(this.subscribeTopic, 0, {}, () => {});
      mqttInstance.registerEvent(
        this.userTopic,
        function (topic, message) {
          let cleanStr = this.cleanString(message);
          /* Convert to JSON */
          let obj = JSON.parse(cleanStr);
          // eslint-disable-next-line
          console.log(obj);
          if (obj.status !== undefined) {
            // eslint-disable-next-line
            console.log(`Process status ${obj.status}`);
            this.setInfoMsg(obj);
          }
        }.bind(this)
      );
      mqttInstance.registerEvent(
        this.networkTopic,
        function (topic, message) {
          let cleanStr = this.cleanString(message);

          /* Convert to JSON */
          let obj = JSON.parse(cleanStr);
          // eslint-disable-next-line
          console.log(obj);
          if (obj.name !== undefined && obj.signal !== undefined) {
            this.setNetworkMsg(obj);
            this.setWifiSignalStrength(obj.signal);
            this.setWifiName(obj.name);
          }
        }.bind(this)
      );
      mqttInstance.registerEvent(
        this.lidarTopic,
        function (topic, message) {
          let cleanStr = this.cleanString(message);
          /* Convert to JSON */
          let obj = JSON.parse(cleanStr);
          // eslint-disable-next-line
          console.log(obj);
          if (obj.status !== undefined) {
            this.setLidarMsg(obj);
          }
        }.bind(this)
      );
      mqttInstance.registerEvent(
        this.lidarrawTopic,
        function (topic, message) {
          let cleanStr = this.cleanString(message);
          /* Convert to JSON */
          let obj = JSON.parse(cleanStr);
          // eslint-disable-next-line
          console.log(obj);
          if (obj.status !== undefined) {
            this.setLidarRawMsg(obj);
          }
        }.bind(this)
      );
      mqttInstance.registerEvent(
        this.configTopic,
        function (topic, message) {
          let cleanStr = this.cleanString(message);
          /* Convert to JSON */
          let obj = JSON.parse(cleanStr);
          // eslint-disable-next-line
          console.log(obj);
          if (obj.ctrlmode !== undefined) {
            this.setConfigMsg(obj);
          }
        }.bind(this)
      );
      mqttInstance.registerEvent(
        this.registrarTopic,
        function (topic, message) {
          let cleanStr = this.cleanString(message);
          /* Convert to JSON */
          let obj = JSON.parse(cleanStr);
          // eslint-disable-next-line
          console.log(obj);
          if (obj.registration !== undefined) {
            this.setRegistrationStatus(obj.registration);
          }
        }.bind(this)
      );
      mqttInstance.registerEvent(
        this.remoteAccessTopic,
        function (topic, message) {
          let cleanStr = this.cleanString(message);
          /* Convert to JSON */
          let obj = JSON.parse(cleanStr);
          // eslint-disable-next-line
          console.log(obj);
          if (obj.remoteAccessStatus !== undefined) {
            this.setRemoteAccessStatusMsg(obj);
          }
        }.bind(this)
      );
      mqttInstance.registerEvent(
        this.remoteAccessInfoTopic,
        function (topic, message) {
          let cleanStr = this.cleanString(message);
          /* Convert to JSON */
          let obj = JSON.parse(cleanStr);
          // eslint-disable-next-line
          console.log(obj);
          if (obj.mode !== undefined) {
            this.setRemoteAccessInfoMsg(obj);
          }
        }.bind(this)
      );
    },
  },
};
</script>
