<template>
  <div v-if="getNetworkPageMode">
    <div v-if="getNewNetwork" :style="wifiStyle">
      <p style="margin: 1vh 2vw 1vh 2vw">
        Please connect to "{{ name }}" network and scan NFC tag to obtain a link
      </p>
    </div>
    <!--WiFi style Begin -->
    <div v-else :style="wifiStyle">
      <Popper
        class="labelStyle"
        style="position: relative; width: 100%"
        placement="bottom"
        arrow
        offsetDistance="0"
      >
        <template #content>
          <div>
            Smart Light Controller supports two Wi-Fi configuration modes:
            <li>Access Point</li>
            <li>Router Client</li>
            In Access Point mode, the device creates its own Wi-Fi network.
            Other devices can connect directly to this new network using the
            provided Network Name (SSID) and password. This is useful to connect
            devices locally without using an existing home network.The default
            settings of Access Point mode are Network Name:<b>SMALIC</b> and
            Network Password:<b>smalicpass</b>. <br />In Router Client mode, the
            device connects to the existing Wi-Fi network (like home router). It
            is necessary to enter the SSID and password of the existing network,
            and the device will use it to communicate. It is possible to select
            the existing Wi-Fi network from the the drop-down menu. The Router
            Client mode is ideal if the device is part of home network and have
            internet access. <br />To switch between modes just press the button
            below. Also, it is possible to force the system to switch to Access
            Point mode with default settings just pressing 2 horizontal buttons
            of the second line on the physical device to start the pulsing white
            light. In few seconds the default Access Point is activated as soon
            as the pulsing color changes from white to green.
          </div>
        </template>
        <input
          type="text"
          class="label"
          style="width: 100%"
          value="Wi-Fi Connection Help"
          readonly
        />
      </Popper>
      <div>
        <button id="button" class="button" @:click="handleModeClick">
          {{ getNetworkMode }}
        </button>
      </div>
      <div>
        <switches
          color="default"
          style="margin: 0vh 2vw 0vh 2vw"
          :value="nameSwitchStatus"
          @:click="toggleNameForm()"
        ></switches>
        <label class="label1">Network Name</label>
      </div>
      <div>
        <input
          v-if="nameSwitchStatus"
          type="text"
          class="input"
          v-model="name"
          @input="nameChange"
        />
        <select
          v-else
          class="selector"
          v-model="selected"
          @change="onChange($event)"
        >
          <option
            v-for="index in getNetworks"
            :value="index.value"
            :key="index"
          >
            {{ index.text }}
          </option>
        </select>
      </div>
      <div>
        <switches
          color="default"
          style="margin: 0vh 2vw 0vh 2vw"
          :value="passwordSwitchStatus"
          @:click="toggleShow()"
        ></switches>
        <label class="label1">Network Password</label>
      </div>
      <div>
        <form>
          <input
            v-if="passwordSwitchStatus"
            type="text"
            autocomplete="on"
            class="input"
            v-model="password"
            @input="passwordChange"
          />
          <input
            v-else
            type="password"
            autocomplete="on"
            class="input"
            v-model="password"
            @input="passwordChange"
          />
        </form>
      </div>
      <div>
        <button
          id="button"
          class="button"
          v-if="apply"
          @:click="handleApplyClick"
        >
          Apply
        </button>
      </div>
      <!-- Remote Access  begin -->
      <div v-if="configRemoteAccess">
        <div class="separator"></div>
        <!-- Block style  begin -->
        <div :style="blockStyle">
          <Popper
            class="labelStyle"
            style="position: relative; width: 100%"
            placement="bottom"
            arrow
            offsetDistance="0"
          >
            <template #content>
              <div>
                When Remote Access is <b>disabled</b>, the Smart Light
                Controller works locally without an internet connection.
                Benefits include increased privacy and security.<br />
                When Remote Access is <b>enabled</b>, the Smart Light Controller
                can connect to the cloud. Benefits include a remote management,
                software updates and integration with other smart home
                platforms. Additionally, Remote Access enables
                <a href="https://www.dudeiot.com">Remote Client</a>
                that allows users to control the Smart Light Controller via
                internet. <br />For security, the Remote Client and the Smart
                Light Controller use authentication methods (such as user
                accounts and encryption) to ensure that only authorized users
                can control the device. To create the account just specify Name
                and Password. If both the Name and Password fields meet the
                required conditions (Name: 2-30 characters, Password: 8-30
                characters), the Submit button appears. Additionally, the Submit
                button appears if any changes are made in the settings.
                <br />Remote Access Help provides real-time feedback on the
                Submit action. The message indicates whether the Submit action
                (e.g., changing credentials or settings) was successful or if it
                failed. This helps users stay informed about the outcome of
                their real-time.
              </div>
            </template>
            <input
              type="text"
              class="label"
              style="width: 100%"
              :value="getRemoteAccessStatusMessage"
              readonly
            />
          </Popper>
          <!-- Remote Access Action  begin -->
          <div v-if="getRemoteAccessAction">
            <div>
              <switches
                color="default"
                style="margin: 0vh 2vw 0vh 2vw"
                :value="remoteAccessSwitchStatus"
                @:click="toggleRemoteAccessForm()"
              ></switches>
              <label class="label1">{{ remoteAccessStatus }}</label>
            </div>
            <form @submit.prevent="onSubmit" @reset.prevent="onReset">
              <div v-if="remoteAccessSwitchStatus">
                <div>
                  <switches
                    color="default"
                    style="margin: 0vh 2vw 0vh 2vw"
                    :value="remoteAccessClientSwitchStatus"
                    @:click="toggleRemoteAccessClientForm()"
                  ></switches>
                  <label class="label1">{{ remoteAccessClientStatus }}</label>
                </div>
                <div v-if="remoteAccessClientSwitchStatus">
                  <!-- <fieldset> -->
                  <input
                    class="input"
                    id="name"
                    v-model="remoteAccessForm.name"
                    placeholder="Name"
                    type="text"
                    autocomplete="on"
                    required
                    @input="remoteAccessNameChange"
                  />
                  <switches
                    color="default"
                    style="margin: 0vh 2vw 0vh 2vw"
                    :value="remoteAccessPasswordSwitchStatus"
                    @:click="toggleRemoteAccessPasswordForm()"
                  ></switches>
                  <label class="label1">{{ remoteAccessPasswordStatus }}</label>

                  <input
                    class="input"
                    id="passord"
                    v-model="remoteAccessForm.password"
                    placeholder="Password"
                    :type="remoteAccessPasswordType"
                    autocomplete="on"
                    required
                    @input="remoteAccessPasswordChange"
                  />
                </div>
              </div>
              <!-- </fieldset> -->
              <div v-if="submit">
                <input type="submit" class="submitinput" value="Submit" />
              </div>
            </form>
          </div>
          <!-- Remote Access Action End -->
        </div>
        <!-- Block style End -->
      </div>
      <!-- Remote Access End -->
    </div>
    <!--WiFi style End -->
  </div>
  <div v-else :style="wifiStyle">
    <div class="labelStyle" style="width: 100%">
      <div class="conwaiter">
        Settings are only available for <br />Local Version of WEB GUI
      </div>
    </div>
  </div>
</template>
<script>
import AppConstants from "@/AppConstants.js";
import Switches from "/node_modules/vue-switches";
import Popper from "vue3-popper";
import { mapGetters, mapMutations } from "vuex";
const MENU_WIDTH = AppConstants.MENU_WIDTH;
const ROUTER_CLIENT_NAME = "RouterClient";
const ACCESS_POINT_NAME = "AccessPoint";
let size = AppConstants.get2Size();
/* Remote Access */
const REMOTE_ACCESS_INFO_DEFAULT_MESSAGE = "Remote Access Help";
const REMOTE_ACCESS_ENABLED = "Remote Access Enabled";
const REMOTE_ACCESS_DISABLED = "Remote Access Disabled";
const REMOTE_ACCESS_CLIENT_ENABLED = "Remote Client Enabled";
const REMOTE_ACCESS_CLIENT_DISABLED = "Remote Client Disabled";
const REMOTE_ACCESS_PASSWORD_ENABLED = "Password View Enabled";
const REMOTE_ACCESS_PASSWORD_DISABLED = "Password View Disabled";
export default {
  data() {
    return {
      wifiStyle: {
        position: "absolute",
        background: "black",
        border: "1px solid black",
        left: MENU_WIDTH + "px",
        top: "0vh",
        width: size.width + "vw",
        height: "100vh",
        textAlign: "left",
        color: "white",
      },
      blockStyle: {
        position: "relative",
        background: "black",
        border: "1px solid black",
        color: "white",
        display: "inline-block",
        width: size.width + "vw",
      },
      password: null,
      name: null,
      nameSwitchStatus: true,
      passwordSwitchStatus: false,
      remoteAccessSwitchStatus: false,
      remoteAccessPasswordSwitchStatus: false,
      remoteAccessClientSwitchStatus: false,
      selected: 0,
      apply: false,
      submit: false,
      remoteAccessStatus: REMOTE_ACCESS_DISABLED,
      remoteAccessPasswordStatus: "Password View Disabled",
      remoteAccessClientStatus: REMOTE_ACCESS_CLIENT_DISABLED,
      remoteAccessForm: {
        name: "",
        password: "",
      },
      remoteAccessPasswordType: "password",
      configRemoteAccess: AppConstants.CONFIG_REMOTE_ACCESS,
    };
  },
  components: {
    Popper,
    Switches,
  },
  computed: {
    ...mapGetters({
      mqttInstance: "mqttStore/getMqttInstance",
      getMqttUserTopic: "mqttStore/getMqttUserTopic",
      getMqttIsInstanceConnected: "mqttStore/getMqttIsInstanceConnected",
      getNetworkMode: "networkStore/getNetworkMode",
      getNetworks: "networkStore/getNetworks",
      getNewNetwork: "networkStore/getNewNetwork",
      getNetworkPageMode: "networkStore/getNetworkPageMode",
      getRemoteAccessStatusMessage: "mqttStore/getRemoteAccessStatusMessage",
      getRemoteAccessAction: "mqttStore/getRemoteAccessAction",
      getRemoteAccessTimeout: "mqttStore/getRemoteAccessTimeout",
      getRemoteAccessInfoCounter: "mqttStore/getRemoteAccessInfoCounter",
      getRemoteAccessInfoMode: "mqttStore/getRemoteAccessInfoMode",
      getRemoteAccessInfoClient: "mqttStore/getRemoteAccessInfoClient",
      getRemoteAccessInfoName: "mqttStore/getRemoteAccessInfoName",
      getRemoteAccessInfoPassword: "mqttStore/getRemoteAccessInfoPassword",
    }),
  },
  methods: {
    ...mapMutations({
      setNetworkMode: "networkStore/setNetworkMode",
      setNewNetwork: "networkStore/setNewNetwork",
      setRemoteAccessStatusMessage: "mqttStore/setRemoteAccessStatusMessage",
      setRemoteAccessAction: "mqttStore/setRemoteAccessAction",
      setRemoteAccessTimeout: "mqttStore/setRemoteAccessTimeout",
    }),
    toggleShow() {
      this.passwordSwitchStatus = !this.passwordSwitchStatus;
    },
    toggleNameForm() {
      this.nameSwitchStatus = !this.nameSwitchStatus;
      if (!this.nameSwitchStatus) {
        this.selected = 0;
        this.name = "";
        this.password = "";
        this.apply = false;
      }
    },
    handleModeClick: function () {
      if (this.getNetworkMode == ACCESS_POINT_NAME) {
        this.setNetworkMode(ROUTER_CLIENT_NAME);
        this.nameSwitchStatus = false;
        this.selected = 0;
        this.name = "";
        this.password = "";
        this.apply = false;
      } else if (this.getNetworkMode == ROUTER_CLIENT_NAME) {
        this.setNetworkMode(ACCESS_POINT_NAME);
        this.nameSwitchStatus = true;
        this.name = "SMALiC";
        this.password = "smalicpass";
        this.apply = true;
      }
    },
    handleApplyClick: function () {
      let message = "{";
      message += "'mode':'" + this.getNetworkMode + "'";
      message += ",'name':'" + this.name + "'";
      message += ",'password':'" + this.password + "'";
      message += "}";
      this.setNewNetwork(true);
      this.mqttInstance.publish(
        this.getMqttUserTopic + "/credentials",
        message,
        0,
        {},
        () => {}
      );
    },
    onChange: function (event) {
      if (event.target.value) {
        this.nameSwitchStatus = true;
        this.name = this.getNetworks[event.target.value].text;
        this.password = "";
      }
    },
    passwordChange: function (event) {
      if (
        event.target.value &&
        this.name &&
        2 <= this.name.length &&
        32 >= this.name.length &&
        8 <= this.password.length &&
        63 >= this.password.length
      ) {
        this.apply = true;
      } else {
        this.apply = false;
      }
    },
    nameChange: function (event) {
      if (
        event.target.value &&
        2 <= this.name.length &&
        32 >= this.name.length &&
        this.password &&
        8 <= this.password.length &&
        63 >= this.password.length
      ) {
        this.apply = true;
      } else {
        this.apply = false;
      }
    },
    toggleRemoteAccessForm() {
      this.submit = true;
      this.setRemoteAccessStatusMessage(REMOTE_ACCESS_INFO_DEFAULT_MESSAGE);
      this.remoteAccessSwitchStatus = !this.remoteAccessSwitchStatus;
      if (this.remoteAccessSwitchStatus) {
        this.remoteAccessStatus = REMOTE_ACCESS_ENABLED;
      } else {
        this.remoteAccessStatus = REMOTE_ACCESS_DISABLED;
        this.remoteAccessClientStatus = REMOTE_ACCESS_CLIENT_DISABLED;
        this.remoteAccessPasswordStatus = REMOTE_ACCESS_PASSWORD_DISABLED;
        this.remoteAccessClientSwitchStatus = false;
        this.remoteAccessPasswordSwitchStatus = false;
        this.remoteAccessPasswordType = "password";
        this.remoteAccessForm.name = "";
        this.remoteAccessForm.password = "";
      }
    },
    toggleRemoteAccessClientForm() {
      this.setRemoteAccessStatusMessage(REMOTE_ACCESS_INFO_DEFAULT_MESSAGE);
      this.remoteAccessClientSwitchStatus =
        !this.remoteAccessClientSwitchStatus;
      /* -- */
      if (this.remoteAccessClientSwitchStatus) {
        this.remoteAccessClientStatus = REMOTE_ACCESS_CLIENT_ENABLED;
        this.submit = false;
      } else {
        this.submit = true;
        this.remoteAccessClientStatus = REMOTE_ACCESS_CLIENT_DISABLED;
        this.remoteAccessPasswordStatus = REMOTE_ACCESS_PASSWORD_DISABLED;
        this.remoteAccessPasswordSwitchStatus = false;
        this.remoteAccessPasswordType = "password";
        this.remoteAccessForm.name = "";
        this.remoteAccessForm.password = "";
      }
    },
    toggleRemoteAccessPasswordForm() {
      this.remoteAccessPasswordSwitchStatus =
        !this.remoteAccessPasswordSwitchStatus;
      /* -- */
      if (this.remoteAccessPasswordSwitchStatus) {
        this.remoteAccessPasswordStatus = REMOTE_ACCESS_PASSWORD_ENABLED;
        this.remoteAccessPasswordType = "text";
      } else {
        this.remoteAccessPasswordStatus = REMOTE_ACCESS_PASSWORD_DISABLED;
        this.remoteAccessPasswordType = "password";
      }
    },
    remoteAccessNameChange: function (event) {
      if (
        event.target.value &&
        2 <= this.remoteAccessForm.name.length &&
        30 >= this.remoteAccessForm.name.length &&
        this.remoteAccessForm.password &&
        8 <= this.remoteAccessForm.password.length &&
        30 >= this.remoteAccessForm.password.length
      ) {
        this.submit = true;
      } else {
        this.submit = false;
      }
    },
    remoteAccessPasswordChange: function (event) {
      if (
        event.target.value &&
        8 <= this.remoteAccessForm.password.length &&
        30 >= this.remoteAccessForm.password.length &&
        this.remoteAccessForm.name &&
        2 <= this.remoteAccessForm.name.length &&
        30 >= this.remoteAccessForm.name.length
      ) {
        this.submit = true;
      } else {
        this.submit = false;
      }
    },
    onEnableRemoteAccessAction() {
      if (!this.getRemoteAccessAction) {
        this.setRemoteAccessStatusMessage("Error: time-out");
        this.setRemoteAccessAction(1);
      }
    },
    onSubmit() {
      let timeout = setTimeout(this.onEnableRemoteAccessAction, 30000);
      this.setRemoteAccessTimeout(timeout);
      this.setRemoteAccessAction(0);
      let remoteAccessStatus, remoteAccessClientStatus;

      if (!this.remoteAccessStatus.localeCompare(REMOTE_ACCESS_ENABLED)) {
        remoteAccessStatus = "Enabled";
      } else {
        remoteAccessStatus = "Disabled";
      }
      if (
        !this.remoteAccessClientStatus.localeCompare(
          REMOTE_ACCESS_CLIENT_ENABLED
        )
      ) {
        remoteAccessClientStatus = "Enabled";
      } else {
        remoteAccessClientStatus = "Disabled";
      }
      let message = "{";
      message += "'mode':'" + remoteAccessStatus + "'";
      message += ",'client':'" + remoteAccessClientStatus + "'";
      message += ",'name':'" + this.remoteAccessForm.name + "'";
      message += ",'password':'" + this.remoteAccessForm.password + "'";
      message += "}";
      this.mqttInstance.publish(
        this.getMqttUserTopic + "/remoteAccess/request",
        message,
        0,
        {},
        () => {}
      );
      // eslint-disable-next-line
       console.log(message);
    },
  },
  mounted() {
    this.setNetworkMode(ROUTER_CLIENT_NAME);
    this.nameSwitchStatus = false;
    this.setNewNetwork(false);
    this.setRemoteAccessStatusMessage(REMOTE_ACCESS_INFO_DEFAULT_MESSAGE);
  },
  created() {
    let message = "{";
    message += "'name':'wifi'";
    message += "}";
    if (this.getMqttIsInstanceConnected) {
      this.mqttInstance.publish(
        this.getMqttUserTopic + "/update",
        message,
        0,
        {},
        () => {}
      );
    }
  },
  watch: {
    getRemoteAccessInfoCounter: function () {
      let mode = this.getRemoteAccessInfoMode;
      let client = this.getRemoteAccessInfoClient;
      let name = this.getRemoteAccessInfoName;
      let password = this.getRemoteAccessInfoPassword;
      //
      if (!mode.localeCompare("Enabled")) {
        this.remoteAccessStatus = REMOTE_ACCESS_ENABLED;
        this.remoteAccessSwitchStatus = true;
      }
      if (!mode.localeCompare("Disabled")) {
        this.remoteAccessStatus = REMOTE_ACCESS_DISABLED;
        this.remoteAccessSwitchStatus = false;
      }
      //
      if (!client.localeCompare("Enabled")) {
        this.remoteAccessClientStatus = REMOTE_ACCESS_CLIENT_ENABLED;
        this.remoteAccessClientSwitchStatus = true;
      }
      if (!client.localeCompare("Disabled")) {
        this.remoteAccessClientStatus = REMOTE_ACCESS_CLIENT_DISABLED;
        this.remoteAccessClientSwitchStatus = false;
      }
      this.remoteAccessForm.name = name;
      this.remoteAccessForm.password = password;
    },
  },
};
/* watch */
</script>
<style scoped>
.submitinput {
  color: white;
  background-color: black;
  font-size: large;
  margin: 1vh 0vw 1vh 0vw;
  width: 100%;
  border: 3px solid #303030;
  padding: 10px;
  border-radius: 25px;
}
.submitinput:hover {
  border: 3px solid white;
}
.submitinput:focus {
  border: 3px solid white;
  outline: none;
}
.input {
  color: white;
  background-color: black;
  font-size: large;
  margin: 1vh 0vw 1vh 0vw;
  width: 100%;
  border: 3px solid #303030;
  padding: 10px;
  border-radius: 25px;
}
.input:hover {
  border: 3px solid white;
}
.input:focus {
  border: 3px solid white;
  outline: none;
}
.label1 {
  font-size: normal;
  color: lightgrey;
}
.button {
  color: white;
  border: 3px solid #303030;
  background-color: black;
  text-align: center;
  text-decoration: none;
  font-size: large;
  transition-duration: 0.4s;
  cursor: pointer;
  position: center;
  width: 100%;
  margin: 1vh 0vw 1vh 0vw;
  padding: 10px;
  border-radius: 25px;
}
.button:hover {
  border: 3px solid white;
}
.selector {
  color: white;
  border: 3px solid #303030;
  background-color: black;
  text-align: center;
  text-decoration: none;
  font-size: large;
  transition-duration: 0.4s;
  cursor: pointer;
  position: center;
  width: 100%;
  margin: 1vh 0vw 1vh 0vw;
  padding: 10px;
  border-radius: 25px;
}
.separator {
  height: 1px;
  background-color: white;
  margin: 10px 0;
}
.labelStyle {
  position: relative;
  text-align: left;
  float: left;
  --popper-theme-background-color: white;
  --popper-theme-background-color-hover: white;
  --popper-theme-text-color: black;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 25px;
  --popper-theme-padding: 10px;
}
.label {
  position: relative;
  color: gray;
  background-color: black;
  caret-color: transparent;
  font-size: large;
  margin: 1vh 0vw 1vh 0vw;
  border: 3px solid #303030;
  padding: 5px;
  border-radius: 25px;
  text-align: center;
}
.label:hover {
  border: 3px solid white;
}
.label:focus {
  border: 3px solid white;
  outline: none;
}
.conwaiter {
  color: white;
  font-size: x-large;
  margin: auto;
  width: 100%;
  border: 3px solid gray;
  padding: 10px;
  text-align: center;
}
</style>
