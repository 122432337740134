export default {
  namespaced: true,
  state: {
    lidarMsg: {},
    lidarRawMsg: {},
    statusMessage: "Loading...",
    mode: 0,
    savedMode: 0,
    status: 0,
    activation: 0,
    deActivation: 0,
    sensitivity: 1,
    sensorSensitivity: 1,
    updateCounter: 0,
    min: null,
    max: null,
    timer: null,
    isReady: false,
    apps: [{ value: 0, text: "" }],
    /* Zone Detector */
    zone: 0,
    zoneMinArray: ["0", "0", "0", "0", "0", "0", "0", "0"],
    zoneMaxArray: ["0", "0", "0", "0", "0", "0", "0", "0"],
    zoneTimerArray: ["0", "0", "0", "0", "0", "0", "0", "0"],
    zoneActivationArray: [0, 0, 0, 0, 0, 0, 0, 0],
    zoneDeActivationArray: [0, 0, 0, 0, 0, 0, 0, 0],
    zoneTimerTriggerArray: [0, 0, 0, 0, 0, 0, 0, 0],
    /* Raw Sensor */
    sensorModeArray: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    sensorValueArray: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    sensorStatusArray: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    sensorUpdateCounter: 0,
  },
  getters: {
    getApps: (state) => {
      return state.apps;
    },
    getMode: (state) => {
      return state.mode;
    },
    getSavedMode: (state) => {
      return state.savedMode;
    },
    getStatus: (state) => {
      return state.status;
    },
    getStatusMessage: (state) => {
      return state.statusMessage;
    },
    getActivation: (state) => {
      return state.activation;
    },
    getDeActivation: (state) => {
      return state.deActivation;
    },
    getSensitivity: (state) => {
      return state.sensitivity;
    },
    getSensorSensitivity: (state) => {
      return state.sensorSensitivity;
    },
    getUpdateCounter: (state) => {
      return state.updateCounter;
    },
    getMin: (state) => {
      return state.min;
    },
    getMax: (state) => {
      return state.max;
    },
    getTimer: (state) => {
      return state.timer;
    },
    isReady: (state) => {
      return state.isReady;
    },
    getZone: (state) => {
      return state.zone;
    },
    getZoneMin: (state) => {
      return state.zoneMinArray[state.zone];
    },
    getZoneMax: (state) => {
      return state.zoneMaxArray[state.zone];
    },
    getZoneTimer: (state) => {
      return state.zoneTimerArray[state.zone];
    },
    getZoneTimerTrigger: (state) => {
      return state.zoneTimerTriggerArray[state.zone];
    },
    getZoneActivation: (state) => {
      return state.zoneActivationArray[state.zone];
    },
    getZoneDeActivation: (state) => {
      return state.zoneDeActivationArray[state.zone];
    },
    getZoneMinArray: (state) => {
      let arrayStr = "";
      state.zoneMinArray.forEach((element, ndx) => {
        arrayStr += element;
        if (ndx < state.zoneMinArray.length - 1) {
          arrayStr += ",";
        }
      });
      return arrayStr;
    },
    getZoneMaxArray: (state) => {
      let arrayStr = "";
      state.zoneMaxArray.forEach((element, ndx) => {
        arrayStr += element;
        if (ndx < state.zoneMaxArray.length - 1) {
          arrayStr += ",";
        }
      });
      return arrayStr;
    },
    getZoneTimerArray: (state) => {
      let arrayStr = "";
      state.zoneTimerArray.forEach((element, ndx) => {
        arrayStr += element;
        if (ndx < state.zoneTimerArray.length - 1) {
          arrayStr += ",";
        }
      });
      return arrayStr;
    },
    getZoneTimerTriggerArray: (state) => {
      let arrayStr = "";
      state.zoneTimerTriggerArray.forEach((element, ndx) => {
        arrayStr += element.toString();
        if (ndx < state.zoneTimerTriggerArray.length - 1) {
          arrayStr += ",";
        }
      });
      return arrayStr;
    },
    getZoneActivationArray: (state) => {
      let arrayStr = "";
      state.zoneActivationArray.forEach((element, ndx) => {
        arrayStr += element.toString();
        if (ndx < state.zoneActivationArray.length - 1) {
          arrayStr += ",";
        }
      });
      return arrayStr;
    },
    getZoneDeActivationArray: (state) => {
      let arrayStr = "";
      state.zoneDeActivationArray.forEach((element, ndx) => {
        arrayStr += element.toString();
        if (ndx < state.zoneDeActivationArray.length - 1) {
          arrayStr += ",";
        }
      });
      return arrayStr;
    },
    getSensorMode: (state) => (index) => {
      return state.sensorModeArray[index];
    },
    getSensorValue: (state) => (index) => {
      return state.sensorValueArray[index];
    },
    getSensorStatus: (state) => (index) => {
      return state.sensorStatusArray[index];
    },
    getSensorUpdateCounter: (state) => {
      return state.sensorUpdateCounter;
    },
    getSensorArray: (state) => {
      let arrayStr = "";
      state.sensorModeArray.forEach((element, ndx) => {
        arrayStr += element.toString();
        if (ndx < state.sensorModeArray.length - 1) {
          arrayStr += ",";
        }
      });
      return arrayStr;
    },
  },
  mutations: {
    setLidarMsg: (state, val) => {
      state.lidarMsg = val;
      if (Object.prototype.hasOwnProperty.call(state.lidarMsg, "min")) {
        state.min = state.lidarMsg.min.toString();
      }
      if (Object.prototype.hasOwnProperty.call(state.lidarMsg, "max")) {
        state.max = state.lidarMsg.max.toString();
      }
      if (Object.prototype.hasOwnProperty.call(state.lidarMsg, "timer")) {
        state.timer = state.lidarMsg.timer.toString();
      }
      if (Object.prototype.hasOwnProperty.call(state.lidarMsg, "activation")) {
        state.activation = state.lidarMsg.activation;
      }
      if (
        Object.prototype.hasOwnProperty.call(state.lidarMsg, "deactivation")
      ) {
        state.deActivation = state.lidarMsg.deactivation;
      }
      if (Object.prototype.hasOwnProperty.call(state.lidarMsg, "sensitivity")) {
        state.sensitivity = state.lidarMsg.sensitivity;
      }
      if (Object.prototype.hasOwnProperty.call(state.lidarMsg, "mode")) {
        state.savedMode = state.mode = state.lidarMsg.mode;
        state.apps = [
          { value: 0, text: "Not Selected" },
          { value: 1, text: "Motion Detector" },
          { value: 2, text: "Zone Detector" },
          { value: 3, text: "ToF Sensor" },
        ];
      }
      /* ZONE DETECTOR */
      if (Object.prototype.hasOwnProperty.call(state.lidarMsg, "zone")) {
        state.zone = state.lidarMsg.zone;
      }
      if (Object.prototype.hasOwnProperty.call(state.lidarMsg, "status")) {
        state.status = state.lidarMsg.status;
      }
      if (Object.prototype.hasOwnProperty.call(state.lidarMsg, "minArray")) {
        for (let i = 0; i < state.lidarMsg.minArray.length; i++) {
          state.zoneMinArray[i] = state.lidarMsg.minArray[i];
        }
      }
      if (Object.prototype.hasOwnProperty.call(state.lidarMsg, "maxArray")) {
        for (let i = 0; i < state.lidarMsg.maxArray.length; i++) {
          state.zoneMaxArray[i] = state.lidarMsg.maxArray[i];
        }
      }
      if (Object.prototype.hasOwnProperty.call(state.lidarMsg, "timerArray")) {
        for (let i = 0; i < state.lidarMsg.timerArray.length; i++) {
          state.zoneTimerArray[i] = state.lidarMsg.timerArray[i];
        }
      }
      if (
        Object.prototype.hasOwnProperty.call(
          state.lidarMsg,
          "timerTriggerArray"
        )
      ) {
        for (let i = 0; i < state.lidarMsg.timerArray.length; i++) {
          state.zoneTimerTriggerArray[i] = state.lidarMsg.timerTriggerArray[i];
        }
      }
      if (
        Object.prototype.hasOwnProperty.call(state.lidarMsg, "activationArray")
      ) {
        for (let i = 0; i < state.lidarMsg.activationArray.length; i++) {
          state.zoneActivationArray[i] = state.lidarMsg.activationArray[i];
        }
      }
      if (
        Object.prototype.hasOwnProperty.call(
          state.lidarMsg,
          "deactivationArray"
        )
      ) {
        for (let i = 0; i < state.lidarMsg.deactivationArray.length; i++) {
          state.zoneDeActivationArray[i] = state.lidarMsg.deactivationArray[i];
        }
      }
      if (
        Object.prototype.hasOwnProperty.call(
          state.lidarMsg,
          "sensorSensitivity"
        )
      ) {
        state.sensorSensitivity = state.lidarMsg.sensorSensitivity;
      }
      if (Object.prototype.hasOwnProperty.call(state.lidarMsg, "sensorArray")) {
        for (let i = 0; i < state.lidarMsg.sensorArray.length; i++) {
          state.sensorModeArray[i] = state.lidarMsg.sensorArray[i];
        }
      }
      /* -- */
      state.updateCounter++;
    },
    setLidarRawMsg: (state, val) => {
      state.lidarRawMsg = val;
      if (Object.prototype.hasOwnProperty.call(state.lidarRawMsg, "mode")) {
        //state.mode = state.lidarRawMsg.mode;
      }
      if (
        Object.prototype.hasOwnProperty.call(state.lidarRawMsg, "distanceArray")
      ) {
        for (let i = 0; i < state.lidarRawMsg.distanceArray.length; i++) {
          state.sensorValueArray[i] = state.lidarRawMsg.distanceArray[i];
        }
      }
      if (
        Object.prototype.hasOwnProperty.call(state.lidarRawMsg, "statusArray")
      ) {
        for (let i = 0; i < state.lidarRawMsg.statusArray.length; i++) {
          state.sensorStatusArray[i] = state.lidarRawMsg.statusArray[i];
        }
      }
    },
    setMode: (state, val) => {
      state.mode = val;
    },
    setMin: (state, val) => {
      state.min = val;
    },
    setMax: (state, val) => {
      state.max = val;
    },
    setTimer: (state, val) => {
      state.timer = val;
    },
    setReady: (state, val) => {
      state.isReady = val;
    },
    setActivation: (state, val) => {
      state.activation = val;
    },
    setDeActivation: (state, val) => {
      state.deActivation = val;
    },
    setSensitivity: (state, val) => {
      state.sensitivity = val;
    },
    setSensorSensitivity: (state, val) => {
      state.sensorSensitivity = val;
    },
    setZone: (state, val) => {
      state.zone = val;
    },
    setZoneMin: (state, val) => {
      state.zoneMinArray[state.zone] = val;
    },
    setZoneMax: (state, val) => {
      state.zoneMaxArray[state.zone] = val;
    },
    setZoneTimer: (state, val) => {
      state.zoneTimerArray[state.zone] = val;
    },
    setZoneTimerTrigger: (state, val) => {
      state.zoneTimerTriggerArray[state.zone] = val;
    },
    setZoneActivation: (state, val) => {
      state.zoneActivationArray[state.zone] = val;
    },
    setZoneDeActivation: (state, val) => {
      state.zoneDeActivationArray[state.zone] = val;
    },
    setStatusMessage: (state, val) => {
      state.statusMessage = val;
    },
    setSensorMode: (state, val) => {
      state.sensorModeArray[val.index] = val.mode;
      state.sensorUpdateCounter++;
    },
  },
  actions: {},
};
