import AppConstants from "@/AppConstants.js";
import { useMQTT } from "mqtt-vue-hook";
export default {
  namespaced: true,

  state: {
    mqttInstance: null,
    mqttClient: null,
    mqttConnectionString: null,
    mqttUserName: null,
    mqttPassword: null,
    mqttRole: null,
    mqttUserTopic: null,
    mqttIsInstanceConnected: 0,
    mqttIsLoginPage: 0,
    mqttIsLoginPageUpdated: 0,
    mqttLoginMode: AppConstants.CONFIG_MQTT_LOGIN_MODE,
    /* -- */
    remoteAccessStatusMsg: {},
    remoteAccessStatusMessage: "",
    remoteAccessAction: 1,
    remoteAccessTimeout: 0,
    //
    remoteAccessInfoMsg: {},
    remoteAccessInfoCounter: 0,
    remoteAccessInfoMode: "",
    remoteAccessInfoClient: "",
    remoteAccessInfoName: "",
    remoteAccessInfoPassword: "",
  },
  getters: {
    getMqttConnectionString: (state) => {
      return state.mqttConnectionString;
    },
    getMqttUserName: (state) => {
      return state.mqttUserName;
    },
    getMqttPassword: (state) => {
      return state.mqttPassword;
    },
    getMqttRole: (state) => {
      return state.mqttRole;
    },
    getMqttUserTopic: (state) => {
      return state.mqttUserTopic;
    },
    getMqttInstance: (state) => {
      return state.mqttInstance;
    },
    getMqttClient: (state) => {
      return state.mqttClient;
    },
    getMqttIsInstanceConnected: (state) => {
      return state.mqttIsInstanceConnected;
    },
    getMqttIsLoginPage: (state) => {
      return state.mqttIsLoginPage;
    },
    getMqttIsLoginPageUpdated: (state) => {
      return state.mqttIsLoginPageUpdated;
    },
    getMqttLoginMode: (state) => {
      return state.mqttLoginMode;
    },
    getRemoteAccessStatusMessage: (state) => {
      return state.remoteAccessStatusMessage;
    },
    getRemoteAccessAction: (state) => {
      return state.remoteAccessAction;
    },
    getRemoteAccessTimeout: (state) => {
      return state.remoteAccessTimeout;
    },
    getRemoteAccessInfoCounter: (state) => {
      return state.remoteAccessInfoCounter;
    },
    getRemoteAccessInfoMode: (state) => {
      return state.remoteAccessInfoMode;
    },
    getRemoteAccessInfoClient: (state) => {
      return state.remoteAccessInfoClient;
    },
    getRemoteAccessInfoName: (state) => {
      return state.remoteAccessInfoName;
    },
    getRemoteAccessInfoPassword: (state) => {
      return state.remoteAccessInfoPassword;
    },
  },

  mutations: {
    setMqttConnectionString: (state, val) => {
      state.mqttConnectionString = val;
    },
    setMqttUserName: (state, val) => {
      state.mqttUserName = val;
    },
    setMqttPassword: (state, val) => {
      state.mqttPassword = val;
    },
    setMqttRole: (state, val) => {
      state.mqttRole = val;
    },
    setMqttUserTopic: (state, val) => {
      state.mqttUserTopic = val;
    },
    setMqttInstance: (state, val) => {
      state.mqttInstance = val;
    },
    setMqttClient: (state, val) => {
      state.mqttClient = val;
    },
    setMqttIsInstanceConnected: (state, val) => {
      state.mqttIsInstanceConnected = val;
    },
    setMqttIsLoginPage: (state, val) => {
      state.mqttIsLoginPage = val;
    },
    setMqttLoginMode: (state, val) => {
      state.mqttLoginMode = val;
    },
    setMqttIsLoginPageUpdated: (state, val) => {
      state.mqttIsLoginPageUpdated += val;
    },
    setRemoteAccessStatusMsg: (state, val) => {
      let action = 0;
      state.remoteAccessStatusMsg = val;
      if (
        Object.prototype.hasOwnProperty.call(
          state.remoteAccessStatusMsg,
          "remoteAccessStatus"
        )
      ) {
        state.remoteAccessStatusMessage =
          state.remoteAccessStatusMsg.remoteAccessStatus;
        if (!state.remoteAccessStatusMessage.localeCompare("Connected")) {
          action = 1;
        }
        if (!state.remoteAccessStatusMessage.localeCompare("Disconnected")) {
          action = 1;
        }
        if (!state.remoteAccessStatusMessage.localeCompare("SUCCESS")) {
          action = 1;
        }
        if (state.remoteAccessStatusMessage.indexOf("Error") !== -1) {
          action = 1;
          if (state.remoteAccessStatusMessage.indexOf("35421") !== -1) {
            state.remoteAccessStatusMessage = "Choose Different Name";
          }
        }
        if (action) {
          clearTimeout(state.remoteAccessTimeout);
          state.remoteAccessAction = 1;
        }
      }
    },
    setRemoteAccessStatusMessage: (state, val) => {
      state.remoteAccessStatusMessage = val;
    },
    setRemoteAccessAction: (state, val) => {
      state.remoteAccessAction = val;
    },
    setRemoteAccessTimeout: (state, val) => {
      state.remoteAccessTimeout = val;
    },
    setRemoteAccessInfoMsg: (state, val) => {
      state.remoteAccessInfoMsg = val;
      // console.log("RX: " + JSON.stringify(state.remoteAccessInfoMsg));
      if (
        Object.prototype.hasOwnProperty.call(state.remoteAccessInfoMsg, "mode")
      ) {
        state.remoteAccessInfoMode = state.remoteAccessInfoMsg.mode;
      }
      //
      if (
        Object.prototype.hasOwnProperty.call(
          state.remoteAccessInfoMsg,
          "client"
        )
      ) {
        state.remoteAccessInfoClient = state.remoteAccessInfoMsg.client;
      }
      //
      if (
        Object.prototype.hasOwnProperty.call(state.remoteAccessInfoMsg, "name")
      ) {
        state.remoteAccessInfoName = state.remoteAccessInfoMsg.name;
      }
      //
      if (
        Object.prototype.hasOwnProperty.call(
          state.remoteAccessInfoMsg,
          "password"
        )
      ) {
        state.remoteAccessInfoPassword = state.remoteAccessInfoMsg.password;
      }
      //
      state.remoteAccessInfoCounter++;
    },
  },
  actions: {
    mqttConnect(context) {
      let mqttInstance = useMQTT();
      let connectionString = context.getters.getMqttConnectionString;
      let userName = context.getters.getMqttUserName;
      let password = context.getters.getMqttPassword;

      mqttInstance.registerEvent("on-connect", () => {
        context.commit("setMqttIsInstanceConnected", 1);
        // eslint-disable-next-line
        console.log(`MQTT on-connect`);
      });
      mqttInstance.registerEvent("on-disconnect", () => {
        context.commit("setMqttIsInstanceConnected", 0);
        // eslint-disable-next-line
        console.log(`MQTT on-disconnect`);
      });
      mqttInstance.registerEvent("on-reconnect", () => {
        context.commit("setMqttIsInstanceConnected", 0);
        // eslint-disable-next-line
        console.log(`MQTT on-reconnect`);
      });
      mqttInstance.registerEvent("on-connect-fail", () => {
        context.commit("setMqttIsInstanceConnected", 0);
        // eslint-disable-next-line
         console.log(`MQTT on-connect-fail`);
      });

      let mqttClient = mqttInstance.connect(connectionString, {
        username: userName,
        password: password,
        clean: true,
        keepalive: 60,
        clientId: `mqtt_client_${Math.random().toString(16).substring(2, 10)}`,
        connectTimeout: 4000,
      });
      context.commit("setMqttInstance", mqttInstance);
      context.commit("setMqttClient", mqttClient);
    },
    mqttDisconnect(context) {
      let mqttInstance = context.getters.getMqttInstance;
      mqttInstance.disconnect();
    },
  },
};
