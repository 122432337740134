<template>
  <div
    class="bar"
    @:click="toggle"
    :style="{
      marginLeft: properties.margin,
      width: properties.width,
      height: properties.height,
      backgroundColor: getBackgroundColor,
      color: getBackgroundColor,
      display: 'inline-block ',
      border: getBorderColor,
    }"
  ></div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "StripComponent",
  components: {},
  props: ["properties", "index"],
  data: function () {
    return {};
  },
  methods: {
    ...mapMutations({
      setStripStatus: "infoMsgStore/setStripStatus",
      setBrushColor: "infoMsgStore/setBrushColor",
    }),
    toggle() {
      let status = 0;
      if (this.properties.mode) {
        if (this.getColorMode) {
          if (this.getStripStatus(parseInt(this.index) + 1)) {
            status = 0;
          } else {
            status = 1;
          }
          let val = { index: Number, status: Number };
          val.index = this.index + 1;
          val.status = status;
          this.setStripStatus(val);
        } else {
          this.setBrushColor(this.getBackgroundColor);
        }
      }
    },
  },
  computed: {
    getBackgroundColor() {
      let color;
      if (this.properties.mode) {
        color = this.getSettingColorByStripIndex(parseInt(this.index) + 1);
      } else {
        color = this.getColorByStripIndex(parseInt(this.index) + 1);
      }
      return color;
    },
    getBorderColor() {
      let color = "3px solid #303030";
      if (this.properties.mode) {
        if (this.getStripStatus(parseInt(this.index) + 1)) {
          color = "6px solid #909090";
        }
      }
      return color;
    },
    getScenarioNumber() {
      return this.getScenario;
    },
    ...mapGetters({
      mqttInstance: "mqttStore/getMqttInstance",
      getColorByStripIndex: "infoMsgStore/getColorByStripIndex",
      getScenario: "infoMsgStore/getScenario",
      getSettingColorByStripIndex: "infoMsgStore/getSettingColorByStripIndex",
      getStripStatus: "infoMsgStore/getStripStatus",
      getColorMode: "infoMsgStore/getColorMode",
    }),
  },
  mutations: {},
};
</script>
<style scoped></style>
