<template>
  <svg
    width="25%"
    height="25%"
    :style="{ margin: properties.margin, position: 'absolute' }"
    viewBox="0 0 210 210"
    fill="none"
    v-on:click="toggle"
  >
    <path
      d="M182 105C182 147.526 147.526 182 105 182C62.4741 182 28 147.526 28 105C28 62.4741 62.4741 28 105 28C147.526 28 182 62.4741 182 105Z"
      :fill="getColor"
    />
    <path
      d="M28.6964 82H180.775C180.775 82 185 90 185 105C185 120 180.775 128 180.775 128H28.6964C28.6964 128 24.9999 120 25 105C25.0001 90 28.6964 82 28.6964 82Z"
      fill="#303030"
    />
    <path
      d="M56 105C56 77.938 77.938 56 105 56C132.062 56 154 77.938 154 105C154 132.062 132.062 154 105 154C77.938 154 56 132.062 56 105Z"
      fill="#303030"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M105 205C160.228 205 205 160.228 205 105C205 49.7715 160.228 5 105 5C49.7715 5 5 49.7715 5 105C5 160.228 49.7715 205 105 205ZM105 182C147.526 182 182 147.526 182 105C182 62.4741 147.526 28 105 28C62.4741 28 28 62.4741 28 105C28 147.526 62.4741 182 105 182Z"
      :fill="getFocusedColor"
    />
  </svg>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "ButtonComponent",
  components: {},
  props: ["properties", "index"],
  data: function () {
    return {};
  },
  computed: {
    getScenarioNumber() {
      return this.getScenario;
    },
    getColor() {
      let color;
      if (this.properties.mode) {
        color = this.getSettingColorByButtonIndex(parseInt(this.index) + 1);
      } else {
        color = this.getColorByButtonIndex(parseInt(this.index) + 1);
      }
      return color;
    },
    getFocusedColor() {
      let color = "#303030";
      if (this.properties.mode) {
        if (this.getButtonStatus(this.index + 1)) {
          color = "#909090";
        } else {
          color = "#303030";
        }
      } else {
        /* Process default DASHBOARD mode */
        if (this.getScenario == parseInt(this.index) + 1) {
          color = "#606060";
        } else {
          color = "#303030";
        }
      }
      return color;
    },
    ...mapGetters({
      mqttInstance: "mqttStore/getMqttInstance",
      getMqttUserTopic: "mqttStore/getMqttUserTopic",
      getColorByButtonIndex: "infoMsgStore/getColorByButtonIndex",
      getScenario: "infoMsgStore/getScenario",
      getSettingColorByButtonIndex: "infoMsgStore/getSettingColorByButtonIndex",
      getButtonStatus: "infoMsgStore/getButtonStatus",
      getColorMode: "infoMsgStore/getColorMode",
    }),
  },
  methods: {
    ...mapMutations({
      setButtonStatus: "infoMsgStore/setButtonStatus",
      setButtonSelectedScenario: "infoMsgStore/setButtonSelectedScenario",
      setBrushColor: "infoMsgStore/setBrushColor",
    }),
    toggle() {
      let status = 0;
      if (this.properties.mode) {
        if (this.getColorMode) {
          if (this.getButtonStatus(this.index + 1)) {
            status = 0;
          } else {
            status = 1;
          }
          let val = { index: Number, status: Number };
          val.index = this.index + 1;
          val.status = status;
          this.setButtonStatus(val);
          this.setButtonSelectedScenario(val.index);
        } else {
          this.setBrushColor(this.getColor);
        }
      } else {
        /* Process default DASHBOARD mode */
        let scenarioNumber = this.index + 1;
        let scenarioNumberkHex = scenarioNumber.toString(16);
        if (scenarioNumberkHex.length < 2) {
          scenarioNumberkHex = "0" + scenarioNumberkHex;
        }
        // eslint-disable-next-line
        console.log("Button userTopic:"+this.getMqttUserTopic);
        this.mqttInstance.publish(
          this.getMqttUserTopic,
          "{'addr':32,'payload':'" + scenarioNumberkHex + "'}",
          0,
          {},
          () => {}
        );
      }
    },
  },
  mutations: {
    setColor(color) {
      this.color = color;
    },
  },
};
</script>
