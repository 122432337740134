<template>
  <div>
    <FormLogin />
  </div>
</template>

<script>
import FormLogin from "../components/FormLogin";
export default {
  components: {
    FormLogin,
  },
};
</script>

<style scoped></style>
