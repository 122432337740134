<template>
  <div id="container" :style="containerStyle">
    <ButtonComponent
      v-for="(properties, index) in buttonProperties"
      :properties="properties"
      :index="index"
      :key="index"
    />
  </div>
</template>

<script>
/* <h1 class="card-text" v-html="getDebugMessage"></h1> */
import ButtonComponent from "@/components/Button.vue";
import { mapGetters } from "vuex";
import AppConstants from "@/AppConstants.js";
const MENU_WIDTH = AppConstants.MENU_WIDTH;
const NUMBER_OF_BUTTONS = AppConstants.NUMBER_OF_BUTTONS;
export default {
  name: "Panel",
  components: { ButtonComponent },
  props: {
    width: Number,
    height: Number,
    top: Number,
    mode: Number,
  },
  data: function () {
    return {
      containerStyle: {
        border: "1px solid black",
        margin: "0 auto",
        position: "absolute",
        left: MENU_WIDTH + "px",
        width: this.width + "vw",
        height: this.height + "vw",
        top: this.top + "vh",
        background: "black",
      },
      buttonProperties: [],
      buttonPropertiesSize: NUMBER_OF_BUTTONS,
    };
  },
  methods: {},
  computed: {
    ...mapGetters({
      getDebugMessage: "infoMsgStore/getDebugMessage",
    }),
  },
  created() {
    /*button properties */
    let margin = [
      "23% 0% 0% 2%",
      "53% 0% 0% 2%",
      "73% 0% 0% 23%",
      "73% 0% 0% 53%",
      "53% 0% 0% 73%",
      "23% 0% 0% 73%",
      "2% 0% 0% 53%",
      "2% 0% 0% 23%",
    ];
    let onColor = [
      "#FFFFFF",
      "#FF0000",
      "#FF4800",
      "#FF9600",
      "#00FF00",
      "#00FF80",
      "#0000FF",
      "#8400FF",
    ];
    let offColor = [
      "grey",
      "grey",
      "grey",
      "grey",
      "grey",
      "grey",
      "grey",
      "grey",
    ];

    for (let i = 0; i < this.buttonPropertiesSize; i++) {
      this.buttonProperties.push({
        margin: margin[i],
        onColor: onColor[i],
        offColor: offColor[i],
        mode: this.mode,
      });
    }
  },
};
</script>

<style scoped></style>
