<template>
  <div id="strippanel" :style="stripPanelStyle">
    <StripComponent
      v-for="(properties, index) in StripProperties"
      :properties="properties"
      :index="index"
      :key="index"
    />
  </div>
</template>

<script>
/* <h1 class="card-text" v-html="getDebugMessage"></h1> */
import StripComponent from "@/components/Strip.vue";
import { mapGetters } from "vuex";
import AppConstants from "@/AppConstants.js";
const MENU_WIDTH = AppConstants.MENU_WIDTH;
const NUMBER_OF_STRIPS = AppConstants.NUMBER_OF_STRIPS;
export default {
  name: "StripPanel",
  components: { StripComponent },
  props: {
    width: Number,
    height: Number,
    top: Number,
    mode: Number,
  },
  data: function () {
    return {
      StripProperties: [],
      StripPropertiesSize: NUMBER_OF_STRIPS,
      stripPanelStyle: {
        border: "1px solid black",
        position: "absolute",
        left: MENU_WIDTH + "px",
        width: this.width + "vw",
        height: this.height + "vh",
        top: this.top + "vh",
        background: "black",
      },
    };
  },
  methods: {},
  computed: {
    ...mapGetters({
      getDebugMessage: "infoMsgStore/getDebugMessage",
    }),
  },
  created() {
    /*Strip properties */
    let barWidth, margin;
    barWidth = this.width / 10;
    margin = (this.width - barWidth * 8) / 9;
    for (let i = 0; i < this.StripPropertiesSize; i++) {
      this.StripProperties.push({
        margin: margin + "vw",
        backgroundColor: "black",
        width: barWidth + "vw",
        height: this.height + "vh",
        mode: this.mode,
      });
    }
  },
  mutations: {
    setStripColor: (state, index, color) => {
      state.StripProperties[index].backgroundColor = color;
    },
  },
};
</script>

<style scoped></style>
