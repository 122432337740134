import { createStore } from "vuex";
import MqttStore from "./modules/MqttStore.js";
import InfoMsgStore from "./modules/InfoMsgStore.js";
import NetworkStore from "./modules/NetworkStore.js";
import LidarStore from "./modules/LidarStore.js";
import ConfigStore from "./modules/ConfigStore.js";

const store = createStore({
  modules: {
    mqttStore: MqttStore,
    infoMsgStore: InfoMsgStore,
    networkStore: NetworkStore,
    lidarStore: LidarStore,
    configStore: ConfigStore,
  },

  state: {},

  getters: {},

  mutations: {},

  actions: {},
});

export default store;
