<template>
  <div :style="infoStyle">
    <center>
      <p style="margin: 1vh 2vw 1vh 2vw">
        <b>Smart Light Controller</b>
      </p>
    </center>
    <Popper
      class="labelStyle"
      style="position: relative; width: 100%"
      placement="bottom"
      arrow
      offsetDistance="0"
    >
      <template #content>
        <div>
          <b>Dashboard</b> buttons allow to activate different scenarios
          providing a variety of lighting options. To activate a specific
          scenario, simply press the corresponding button. The system will
          adjust its brightness and color temperature based on the predefined
          settings.
        </div>
      </template>
      <input
        type="text"
        class="label"
        style="width: 100%"
        value="Dashboard Help"
        readonly
      />
    </Popper>
    <Popper
      class="labelStyle"
      style="position: relative; width: 100%"
      placement="bottom"
      arrow
      offsetDistance="0"
    >
      <template #content>
        <div>
          <b>Configuration</b> menu option defines the desired player.
          <li>
            <b>Generic Player</b> provides the basic functionality to turn the
            light on and off.
          </li>
          <li>
            <b>Sequence player</b> enables advanced control by playing frame
            sequences to create light shows accompanied with music.
          </li>
          <li>
            <b>DDP (Dynamic Display Protocol) Player</b> facilitates the
            creation and playback of light shows designed with a PC sequencer.
          </li>
        </div>
      </template>
      <input
        type="text"
        class="label"
        style="width: 100%"
        value="Configuration Help"
        readonly
      />
    </Popper>
    <Popper
      class="labelStyle"
      style="position: relative; width: 100%"
      placement="bottom"
      arrow
      offsetDistance="0"
    >
      <template #content>
        <div>
          <b>Setting</b> menu option allows to customize and assign preferred
          colors and brightness to each scenario. <br />Navigate and select the
          desired color adjusting RGB values from 0 up to 255 and color
          temperature controls. Once the necessary color is selected, click any
          scenario button or light strip to apply the chosen color.
          <br />
          <b>Get</b> button allows to obtain color temperature of a selected
          component.
          <br />
          <b>Set</b> button allows to assign color temperature to a selected
          component.
          <br />
          <b>Cor</b> button applies the color temperature correction.
          <br />
          <b>Raw</b> button applies the raw colors without any correction.
          <br />
          <b>View</b> button applies the current scenario settings.
          <br />
          <b>Save</b> button saves the current settings to retentive memory. The
          selected scenario will be activated automatically on the next POWER ON
          event.
        </div>
      </template>
      <input
        type="text"
        class="label"
        style="width: 100%"
        value="Settings Help"
        readonly
      />
    </Popper>
    <Popper
      class="labelStyle"
      style="position: relative; width: 100%"
      placement="bottom"
      arrow
      offsetDistance="0"
    >
      <template #content>
        <div>
          There are few applications based on Time-of-Flight (ToF) Sensor.
          <li>
            <b>Motion Detector</b> detects movement and triggers a predefined
            action, such as turning on lights.
          </li>
          <li>
            <b>Zone Detector</b> measures the minimum distance to an object or
            person allowing for zone-specific actions.
          </li>
          <li>
            <b>ToF Sensor</b> application provides detailed information of raw
            distance data to objects.
          </li>
        </div>
      </template>
      <input
        type="text"
        class="label"
        style="width: 100%"
        value="Applications Help"
        readonly
      />
    </Popper>
  </div>
</template>
<script>
import AppConstants from "@/AppConstants.js";
import Popper from "vue3-popper";
const MENU_WIDTH = AppConstants.MENU_WIDTH;
let size = AppConstants.get2Size();
export default {
  data() {
    return {
      infoStyle: {
        position: "absolute",
        background: "black",
        border: "1px solid black",
        left: MENU_WIDTH + "px",
        top: "0vh",
        width: size.width + "vw",
        height: "100vh",
        textAlign: "left",
        color: "white",
      },
    };
  },
  components: {
    Popper,
  },
};
</script>
<style scoped>
.labelStyle {
  position: relative;
  text-align: left;
  float: left;
  --popper-theme-background-color: white;
  --popper-theme-background-color-hover: white;
  --popper-theme-text-color: black;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 25px;
  --popper-theme-padding: 10px;
}
.label {
  position: relative;
  color: gray;
  background-color: black;
  caret-color: transparent;
  font-size: large;
  margin: 1vh 0vw 1vh 0vw;
  border: 3px solid #303030;
  padding: 5px;
  border-radius: 25px;
  text-align: center;
}
.label:hover {
  border: 3px solid white;
}
.label:focus {
  border: 3px solid white;
  outline: none;
}
</style>
