import { createApp } from "vue";
import colorInput from "vue-color-input";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "boxicons/css/boxicons.min.css";

const app = createApp(App);
app.use(store);
app.use(router);
app.use(colorInput);
app.mount("#app");
