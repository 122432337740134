import AppConstants from "@/AppConstants.js";
/* -- */
export default {
  namespaced: true,
  state: {
    mode: AppConstants.CONFIG_MODE,
    /* --- */
    configMsg: {},
    ctrlmode: "0",
    updateCounter: 0,
    statusPage: 1,
    volumeArray: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    sequenceArray: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    mediaArray: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    sequenceFileArray: [{ value: 0, text: "" }],
    mediaFileArray: [{ value: 0, text: "" }],
    ctrlmodes: [],
    registrationStatus: "",
  },
  getters: {
    getMode: (state) => {
      return state.mode;
    },
    getCtrlMode: (state) => {
      return state.ctrlmode;
    },
    getCtrlModes: (state) => {
      if (AppConstants.CONFIG_PRIMARY_MODE == state.mode) {
        /* Primary mode */
        state.ctrlmodes = [
          { value: 255, text: "Generic" },
          { value: 253, text: "Sequence" },
          { value: 252, text: "DDP" },
        ];
      } else if (AppConstants.CONFIG_INSTALLATION_MODE == state.mode) {
        state.ctrlmodes = [{ value: 255, text: "Generic" }];
      } else if (AppConstants.CONFIG_REGISTRATION_MODE == state.mode) {
        state.ctrlmodes = [{ value: 255, text: "Generic" }];
      }
      return state.ctrlmodes;
    },
    getRegistrationStatus: (state) => {
      return state.registrationStatus;
    },
    getUpdateCounter: (state) => {
      return state.updateCounter;
    },
    getSequenceFiles: (state) => {
      return state.sequenceFileArray;
    },
    getMediaFiles: (state) => {
      return state.mediaFileArray;
    },
    getSequenceByIndex: (state) => (index) => {
      return state.sequenceArray[index - 1];
    },
    getSequenceFileByIndex: (state) => (index) => {
      let sequenceFileArrayIndex = state.sequenceArray[index - 1];
      return state.sequenceFileArray[sequenceFileArrayIndex].text;
    },
    getMediaByIndex: (state) => (index) => {
      return state.mediaArray[index - 1];
    },
    getMediaFileByIndex: (state) => (index) => {
      let mediaFileArrayIndex = state.mediaArray[index - 1];
      return state.mediaFileArray[mediaFileArrayIndex].text;
    },
    getVolumeByIndex: (state) => (index) => {
      return state.volumeArray[index - 1];
    },
    getStatusPage: (state) => {
      return state.statusPage;
    },
  },
  mutations: {
    setConfigMsg: (state, val) => {
      state.configMsg = val;
      if (Object.prototype.hasOwnProperty.call(state.configMsg, "ctrlmode")) {
        state.ctrlmode = state.configMsg.ctrlmode;
      }
      if (
        Object.prototype.hasOwnProperty.call(state.configMsg, "volumeArray")
      ) {
        state.volumeArray = [];
        for (let i = 0; i < state.configMsg.volumeArray.length; i++) {
          state.volumeArray[i] = state.configMsg.volumeArray[i];
        }
      }
      if (
        Object.prototype.hasOwnProperty.call(state.configMsg, "sequenceArray")
      ) {
        state.sequenceArray = [];
        for (let i = 0; i < state.configMsg.sequenceArray.length; i++) {
          state.sequenceArray[i] = state.configMsg.sequenceArray[i];
        }
        if (
          Object.prototype.hasOwnProperty.call(
            state.configMsg,
            "sequenceFileArray"
          )
        ) {
          state.sequenceFileArray = [];
          for (let i = 0; i < state.configMsg.sequenceFileArray.length; i++) {
            state.sequenceFileArray[i] = {
              value: i,
              text: state.configMsg.sequenceFileArray[i],
            };
          }
        }
      }
      if (Object.prototype.hasOwnProperty.call(state.configMsg, "mediaArray")) {
        state.mediaArray = [];
        for (let i = 0; i < state.configMsg.mediaArray.length; i++) {
          state.mediaArray[i] = state.configMsg.mediaArray[i];
        }
        if (
          Object.prototype.hasOwnProperty.call(
            state.configMsg,
            "mediaFileArray"
          )
        ) {
          state.mediaFileArray = [];
          for (let i = 0; i < state.configMsg.mediaFileArray.length; i++) {
            state.mediaFileArray[i] = {
              value: i,
              text: state.configMsg.mediaFileArray[i],
            };
          }
        }
      }
      state.updateCounter++;
    },
    setSequence: (state, val) => {
      state.sequenceArray[val.index - 1] = val.value;
    },
    setMedia: (state, val) => {
      state.mediaArray[val.index - 1] = val.value;
    },
    setVolume: (state, val) => {
      state.volumeArray[val.index - 1] = val.value;
    },
    setStatusPage: (state, val) => {
      state.statusPage = val;
    },
    setRegistrationStatus: (state, val) => {
      state.registrationStatus = val;
    },
  },
  actions: {},
};
