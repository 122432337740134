<template>
  <div v-if="getMqttIsInstanceConnected">
    <div v-if="0 == getMode">
      <div v-if="255 == getCtrlMode">
        <div v-if="getIsColorSchemaUpdated">
          <ColorInputPanelComponent
            :width="colorWidth"
            :height="colorHeight"
            :top="colorTop"
            :mode="colorMode"
            :selectedScenario="colorSelectedScenario"
            :widthpx="colorWidthPx"
          />
          <StripPanelComponent
            :width="stripWidth"
            :height="stripHeight"
            :top="stripTop"
            :mode="stripMode"
          />,
          <PanelComponent
            :width="panelWidth"
            :height="panelHeight"
            :top="panelTop"
            :mode="panelMode"
          />
        </div>
        <div v-else :style="pageStyle">
          <div v-if="255 == getCtrlMode">Please wait for few seconds...</div>
          <div v-else></div>
        </div>
      </div>
      <div v-else :style="pageStyle">
        <div v-if="0 != getCtrlMode">
          Settings are only available for <br />Generic Player Configuration
        </div>
        <div v-else>Please wait for few seconds...</div>
      </div>
    </div>
    <div v-else :style="pageStyle">Disabled</div>
  </div>
  <div v-else :style="pageStyle">
    {{ connectionMsg }}
  </div>
</template>

<script>
// @ is an alias to /src
import AppConstants from "@/AppConstants.js";
import PanelComponent from "@/components/Panel.vue";
import StripPanelComponent from "@/components/StripPanel.vue";
import ColorInputPanelComponent from "@/components/ColorInputPanel.vue";
import { mapGetters } from "vuex";
export default {
  name: "Home",
  data: function () {
    let size = AppConstants.get2Size();
    let adjustedColorHeight = 0.8 * size.height;
    let adjustedStripHeight = 0.2 * size.height;
    return {
      colorWidth: size.width,
      colorWidthPx: size.widthpx,
      colorHeight: adjustedColorHeight,
      colorTop: 0,
      colorMode: 1,
      colorSelectedScenario: 1,
      stripWidth: size.width,
      stripHeight: adjustedStripHeight,
      stripTop: adjustedColorHeight,
      stripMode: 1,
      panelWidth: size.width,
      panelHeight: size.width,
      panelTop: size.height,
      panelMode: 1,
      statusMessage: "Saving...",
      pageStyle: {
        position: "absolute",
        background: "black",
        border: "3px solid gray",
        left: AppConstants.MENU_WIDTH + "px",
        width: size.width + "vw",
        color: "white",
        fontSize: "x-large",
        margin: "auto",
        textAlign: "center",
        padding: "10px",
      },
    };
  },
  components: {
    StripPanelComponent,
    PanelComponent,
    ColorInputPanelComponent,
  },
  computed: {
    ...mapGetters({
      getMqttIsInstanceConnected: "mqttStore/getMqttIsInstanceConnected",
      getScenario: "infoMsgStore/getScenario",
      getCtrlMode: "configStore/getCtrlMode",
      getMode: "configStore/getMode",
      getIsColorSchemaUpdated: "infoMsgStore/getIsColorSchemaUpdated",
    }),
    connectionMsg() {
      return "Waiting for MQTT connection...";
    },
  },
};
</script>
<style scoped>
.conwaiter {
  color: white;
  font-size: x-large;
  margin: auto;
  width: 50%;
  border: 3px solid gray;
  padding: 10px;
  text-align: center;
}
</style>
