import AppConstants from "@/AppConstants.js";
const DEFAULT_NETWORK_MESSAGE = "Select Network";
export default {
  namespaced: true,
  state: {
    networkMsg: {},
    networkMode: "RemoteClient",
    networks: [{ value: 0, text: DEFAULT_NETWORK_MESSAGE }],
    newNetwork: false,
    networkPageMode: AppConstants.CONFIG_NETWORK_PAGE_MODE,
  },
  getters: {
    getNetworks: (state) => {
      return state.networks;
    },
    getNewNetwork: (state) => {
      return state.newNetwork;
    },
    getNetworkPageMode: (state) => {
      return state.networkPageMode;
    },
    getNetworkMode: (state) => {
      if (state.networkMode) {
        return state.networkMode;
      } else {
        return state.networkMsg.mode;
      }
    },
  },
  mutations: {
    setNetworkMsg: (state, val) => {
      state.networkMsg = val;
      state.networks = [];
      state.networks[0] = { value: 0, text: DEFAULT_NETWORK_MESSAGE };
      for (let i = 0; i < state.networkMsg.networks.length; i++) {
        state.networks[i + 1] = {
          value: i + 1,
          text: state.networkMsg.networks[i],
        };
      }
    },
    setNetworkMode: (state, val) => {
      state.networkMode = val;
    },
    setNewNetwork: (state, val) => {
      state.newNetwork = val;
    },
  },
  actions: {},
};
