<template>
  <div id="colorInputPanel" :style="colorPanelStyle">
    <select id="selector" v-model="selected" @change="onChange($event)">
      <option value="1">Scenario #1</option>
      <option value="2">Scenario #2</option>
      <option value="3">Scenario #3</option>
      <option value="4">Scenario #4</option>
      <option value="5">Scenario #5</option>
      <option value="6">Scenario #6</option>
      <option value="7">Scenario #7</option>
      <option value="8">Scenario #8</option>
      <option value="9">Scenario #9 (OFF)</option>
    </select>
    <div style="color: white; text-align: center">
      Color:{{ colorMessage }} Brightness:{{ sliderNumber }}%
    </div>
    <div style="position: relative; width: 100%; border: 1px solid black">
      <Vue3Slider v-model="sliderNumber" />
    </div>
    <div style="position: relative; border: 1px solid black">
      <color-input
        v-model="color"
        ref="colorInput"
        :style="colorInputStyle"
        :disable-alpha="disableAlpha"
        :disabled="disabled"
        :disable-text-inputs="disableTextInputs"
      />
    </div>

    <div>
      <div style="position: relative; border: 1px solid black">
        <button
          class="button"
          :style="colorModeButtonStyle"
          @:click="handleColorMode"
        >
          {{ colorModeMessage }}
        </button>
        <button
          class="button"
          :style="gammaModeButtonStyle"
          @:click="handleGammaMode"
        >
          {{ gammaModeMessage }}
        </button>
        <button
          class="button"
          :style="previewButtonStyle"
          @:click="handlePreviewClick"
        >
          View
        </button>
        <button
          class="button"
          :style="saveButtonStyle"
          @:click="handleSaveClick"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</template>
<script>
/* <h1 class="card-text" v-html="getDebugMessage"></h1> */
import { mapGetters, mapMutations } from "vuex";
import ColorInput from "vue-color-input";
//import Vue3Slider from "vue3-slider";
/* https://www.npmjs.com/package/vue-3-slider-component */
import Vue3Slider from "vue-3-slider-component";
import AppConstants from "@/AppConstants.js";
const MENU_WIDTH = AppConstants.MENU_WIDTH;
function parse_rgb_string(rgb) {
  //rgb = rgb.replace(/[^\d,]/g, "").split(",");
  let colors = ["red", "green", "blue", "alpha"];
  let colorArr = rgb.slice(rgb.indexOf("(") + 1, rgb.indexOf(")")).split(", ");
  let obj = new Object();
  // Insert the values into obj
  colorArr.forEach((k, i) => {
    obj[colors[i]] = k;
  });
  /* Process alpha */
  if (obj.alpha == undefined) {
    obj.alpha = 1;
  }
  obj.alpha *= 100;
  return obj;
}
export default {
  name: "ColorInputPanel",
  components: { ColorInput, Vue3Slider },
  props: {
    width: Number,
    height: Number,
    top: Number,
    mode: Number,
    selectedScenario: Number,
    widthpx: Number,
  },
  data: function () {
    /* Reset all settings */
    let val = { index: Number, status: Number, color: String, alpha: String };
    let ndx;
    this.setScenario(0);
    for (ndx = 1; 9 > ndx; ndx++) {
      val.index = ndx;
      val.color = "#000000";
      val.status = 0;
      val.alpha = "0A";
      this.setStripColor(val);
      this.setButtonColor(val);
      this.setStripStatus(val);
      this.setButtonStatus(val);
      this.setButtonAlpha(val);
    }
    /* -- */
    return {
      color: "rgba(48, 48, 48, 1)",
      colorModeMessage: "Get",
      colorMode: 0,
      gammaModeMessage: "Cor",
      gammaMode: 1,
      disableAlpha: true,
      disabled: false,
      disableTextInputs: false,
      sliderNumber: 10,
      scenario: this.selectedScenario,
      selected: this.selectedScenario,
      previewButtonStyle: "color: white",
      saveButtonStyle: "color: white",
      colorModeButtonStyle: "color: white",
      gammaModeButtonStyle: "color: white",
      colorPanelStyle: {
        border: "1px solid black",
        position: "absolute",
        left: MENU_WIDTH + "px",
        width: this.width + "vw",
        height: this.height + "vh",
        top: this.top + "vh",
        background: "black",
      },
      colorInputStyle: {
        /* left: this.width / 2 + "vw", */
        left: this.widthpx / 2 - 20 + "px",
        /*
        user: {
          box: {
            borderRadius: "5vw",
            height: "10vw",
            width: "10vw",
            border: "3px solid #303030",
          },
        }, */
      },
    };
  },
  methods: {
    ...mapMutations({
      setStripColor: "infoMsgStore/setStripColor",
      setButtonColor: "infoMsgStore/setButtonColor",
      setStripStatus: "infoMsgStore/setStripStatus",
      setButtonStatus: "infoMsgStore/setButtonStatus",
      setScenario: "infoMsgStore/setScenario",
      setButtonAlpha: "infoMsgStore/setButtonAlpha",
      setColorMode: "infoMsgStore/setColorMode",
      setIsColorSchemaUpdated: "infoMsgStore/setIsColorSchemaUpdated",
    }),
    handlePreviewClick: function () {
      let ndx, color, regAddr, brightness;
      let colorStr, regAddrStr;
      let hexStr, hexByte;
      let alphaStr;
      /* Set color of strips */
      for (ndx = 1; 9 > ndx; ndx++) {
        color = this.getSettingColorByStripIndex(ndx);
        alphaStr = this.getButtonAlpha(ndx);
        /* STRIP_COLOR_REGISTER = 32 + (SCENARIO_NUMBER-1) * 64 + (STRIP_NUMBER-1) * 4 */
        regAddr = 32 + (this.scenario - 1) * 64 + (ndx - 1) * 4;
        hexByte = regAddr & 0xff;
        hexStr = hexByte.toString(16).toUpperCase();
        if (hexStr.length < 2) {
          hexStr = "0" + hexStr;
        }
        regAddrStr = hexStr;
        hexByte = (regAddr >> 8) & 0xff;
        hexStr = hexByte.toString(16).toUpperCase();
        if (hexStr.length < 2) {
          hexStr = "0" + hexStr;
        }
        regAddrStr += hexStr;
        /* Blue, Green, Red */
        colorStr =
          color.charAt(5) +
          color.charAt(6) +
          color.charAt(3) +
          color.charAt(4) +
          color.charAt(1) +
          color.charAt(2);
        /* Brightness */
        brightness = this.sliderNumber;
        if (this.gammaMode) brightness |= 0x80;
        alphaStr = brightness.toString(16);
        if (alphaStr.length < 2) {
          alphaStr = "0" + alphaStr;
        }
        colorStr = colorStr.toUpperCase() + alphaStr.toUpperCase();
        this.mqttInstance.publish(
          this.getMqttUserTopic,
          "{'addr':47,'payload':'01" + regAddrStr + colorStr + "'}",
          0,
          {},
          () => {}
        );
      }
      /* Set color of buttons */
      for (ndx = 1; 9 > ndx; ndx++) {
        color = this.getSettingColorByButtonIndex(ndx);
        alphaStr = this.getButtonAlpha(ndx);
        /* BUTTON_COLOR_REGISTER = (SCENARIO_NUMBER-1) * 64 + (BUTTON_NUMBER-1) * 4 */
        regAddr = (this.scenario - 1) * 64 + (ndx - 1) * 4;
        hexByte = regAddr & 0xff;
        hexStr = hexByte.toString(16).toUpperCase();
        if (hexStr.length < 2) {
          hexStr = "0" + hexStr;
        }
        regAddrStr = hexStr;
        hexByte = (regAddr >> 8) & 0xff;
        hexStr = hexByte.toString(16).toUpperCase();
        if (hexStr.length < 2) {
          hexStr = "0" + hexStr;
        }
        regAddrStr += hexStr;
        /* Blue, Green, Red */
        colorStr =
          color.charAt(5) +
          color.charAt(6) +
          color.charAt(3) +
          color.charAt(4) +
          color.charAt(1) +
          color.charAt(2);
        /* Brightness */
        brightness = this.sliderNumber;
        if (this.gammaMode) brightness |= 0x80;
        alphaStr = brightness.toString(16);
        if (alphaStr.length < 2) {
          alphaStr = "0" + alphaStr;
        }
        colorStr = colorStr.toUpperCase() + alphaStr.toUpperCase();
        this.mqttInstance.publish(
          this.getMqttUserTopic,
          "{'addr':47,'payload':'01" + regAddrStr + colorStr + "'}",
          0,
          {},
          () => {}
        );
      }
      /* Refresh scenario */
      hexByte = this.scenario;
      hexStr = hexByte.toString(16).toUpperCase();
      if (hexStr.length < 2) {
        hexStr = "0" + hexStr;
      }
      this.mqttInstance.publish(
        this.getMqttUserTopic,
        "{'addr':30,'payload':'" + hexStr + "'}",
        0,
        {},
        () => {}
      );
    },
    handleSaveClick: function () {
      let hexStr, hexByte;
      /* Power ON  scenario */
      hexByte = this.scenario;
      hexStr = hexByte.toString(16).toUpperCase();
      if (hexStr.length < 2) {
        hexStr = "0" + hexStr;
      }
      this.mqttInstance.publish(
        this.getMqttUserTopic,
        "{'addr':31,'payload':'" + hexStr + "'}",
        0,
        {},
        () => {}
      );
      this.setIsColorSchemaUpdated(0);
    },
    handleColorMode: function () {
      if (this.colorMode) {
        this.colorModeMessage = "Get";
        this.colorMode = 0;
        /* Unselect all buttons  & strips*/
        let val = { index: Number, status: Number };
        val.status = 0;
        for (val.index = 1; 9 > val.index; val.index++) {
          this.setButtonStatus(val);
          this.setStripStatus(val);
        }
      } else {
        this.colorModeMessage = "Set";
        this.colorMode = 1;
      }
      this.setColorMode(this.colorMode);
    },
    handleGammaMode: function () {
      if (this.gammaMode) {
        this.gammaModeMessage = "Raw";
        this.gammaMode = 0;
      } else {
        this.gammaModeMessage = "Cor";
        this.gammaMode = 1;
      }
      this.setColorMode(this.colorMode);
    },
    selectScenario: function (scenario) {
      let ndx, brightness;
      let val = { index: Number, status: Number, color: String, alpha: String };
      /* Reset all settings */
      this.setScenario(0);
      for (ndx = 1; 9 > ndx; ndx++) {
        val.index = ndx;
        val.color = "#000000";
        val.status = 0;
        val.alpha = "0A";
        this.setStripColor(val);
        this.setButtonColor(val);
        this.setStripStatus(val);
        this.setButtonStatus(val);
        this.setButtonAlpha(val);
      }
      /* Process the selected scenario */
      val.index = scenario;
      /* Set selected scenario */
      this.setScenario(val.index);
      for (ndx = 1; 9 > ndx; ndx++) {
        val.index = ndx;
        val.color = this.getColorByStripIndex(ndx);
        this.setStripColor(val);
        val.color = this.getColorByButtonIndex(ndx);
        this.setButtonColor(val);
        /* Brightness */
        if (8 == ndx) {
          val.color = this.getWhiteColorByStripIndex(ndx);
          let colorStr = val.color.charAt(1) + val.color.charAt(2);
          brightness = parseInt(colorStr.toString(), 16);

          if (0x80 & brightness) {
            this.gammaModeMessage = "Cor";
            this.gammaMode = 1;
          } else {
            this.gammaModeMessage = "Raw";
            this.gammaMode = 0;
          }
          brightness &= 0x7f;
          if (100 < brightness) brightness = 100;
          this.sliderNumber = brightness;
        }
      }
      this.color = "rgba(0, 0, 0, 1)";
      /* Switch to current scenario */
      this.setScenario(0);
    },
    onChange: function (event) {
      if (event.target.value <= 9) {
        this.scenario = event.target.value;
        this.selectScenario(this.scenario);
      }
    },
  },
  computed: {
    ...mapGetters({
      mqttInstance: "mqttStore/getMqttInstance",
      getMqttUserTopic: "mqttStore/getMqttUserTopic",
      getDebugMessage: "infoMsgStore/getDebugMessage",
      getWhiteColorByStripIndex: "infoMsgStore/getWhiteColorByStripIndex",
      getColorByStripIndex: "infoMsgStore/getColorByStripIndex",
      getColorByButtonIndex: "infoMsgStore/getColorByButtonIndex",
      getSettingColorByStripIndex: "infoMsgStore/getSettingColorByStripIndex",
      getSettingColorByButtonIndex: "infoMsgStore/getSettingColorByButtonIndex",
      getScenario: "infoMsgStore/getScenario",
      getStripStatus: "infoMsgStore/getStripStatus",
      getButtonStatus: "infoMsgStore/getButtonStatus",
      getButtonAlpha: "infoMsgStore/getButtonAlpha",
      getButtonSelectedScenario: "infoMsgStore/getButtonSelectedScenario",
      getAdjustedColorString: "infoMsgStore/getAdjustedColorString",
      getUpdateCounter: "infoMsgStore/getUpdateCounter",
      getBrushColor: "infoMsgStore/getBrushColor",
      getColorMode: "infoMsgStore/getColorMode",
    }),
    colorMessage() {
      let ndx;
      let val = { index: Number, color: String, alpha: String };
      let colorStr = this.color.toString();
      let colorObj = parse_rgb_string(colorStr);
      let redHex = parseInt(colorObj.red).toString(16).toUpperCase();
      let greenHex = parseInt(colorObj.green).toString(16).toUpperCase();
      let blueHex = parseInt(colorObj.blue).toString(16).toUpperCase();
      let alphaHex = "0A";
      if (2 > redHex.length) {
        redHex = "0" + redHex;
      }
      if (2 > greenHex.length) {
        greenHex = "0" + greenHex;
      }
      if (2 > blueHex.length) {
        blueHex = "0" + blueHex;
      }
      if (2 > alphaHex.length) {
        alphaHex = "0" + alphaHex;
      }
      colorStr = "#" + redHex + greenHex + blueHex;
      for (ndx = 1; 9 > ndx; ndx++) {
        val.index = ndx;
        val.color = colorStr;
        val.alpha = alphaHex;
        if (this.colorMode) {
          if (this.getStripStatus(val.index)) {
            this.setStripColor(val);
          }
          if (this.getButtonStatus(val.index)) {
            this.setButtonColor(val);
          }
          this.setButtonAlpha(val);
        }
      }
      return colorStr;
    },
  },
  mutations: {},
  mounted() {
    let selectedScenario = this.getScenario;
    this.selectScenario(selectedScenario);
    this.scenario = this.selected = selectedScenario;
    this.colorModeMessage = "Get";
    this.colorMode = 0;
  },
  watch: {
    getUpdateCounter: function () {
      if (!this.colorMode) {
        let colorStr = this.getBrushColor;
        /* Parse colorStr  as "#RGB" HEX string   */
        let redCode = colorStr.charAt(1) + colorStr.charAt(2);
        let greenCode = colorStr.charAt(3) + colorStr.charAt(4);
        let blueCode = colorStr.charAt(5) + colorStr.charAt(6);
        /* -- */
        let redColor = parseInt(redCode.toString(), 16);
        let greenColor = parseInt(greenCode.toString(), 16);
        let blueColor = parseInt(blueCode.toString(), 16);
        /* Set the color as rgba(R, G, B, A) */
        colorStr =
          "rgba(" + redColor + ", " + greenColor + ", " + blueColor + ", 1)";
        this.color = colorStr;
      }
    },
  },
};
</script>
<style>
#selector {
  color: white;
  background-color: black;
  font-size: large;
  border: 3px solid #303030;
  padding: 1px;
  width: 100%;
  height: auto;
  text-align: center;
  font-family: Verdana, Arial, sans-serif;
  border-radius: 5vw;
}
.button {
  border: 3px solid #303030;
  background-color: black;
  text-align: center;
  text-decoration: none;
  font-size: large;
  transition-duration: 0.4s;
  cursor: pointer;
  /* position: relative; */
  width: 25%;
  margin: 0% 0% 0% 0%;
  padding: 5px;
  border-radius: 25px;
}
.button:hover {
  border: 3px solid white;
}
color-input.user.user .picker-popup {
  position: bottom;
  z-index: 9999;
  width: auto;
  min-width: 250px;
  background-color: #303030;
  box-shadow: 0px 5px 10px rgba(15, 15, 15, 0.4);
  user-select: none;
  color: black;
  border: 2px solid white;
}
.color-input.user.user .box {
  position: relative;
  border-radius: 20px;
  height: 40px;
  width: 40px;
  border: 3px solid #303030;
}
</style>
