<template>
  <div class="login">
    <p style="margin: 0vh 2vw 1vh 2vw">Welcome to SmaLiC</p>
    <form @submit.prevent="onSubmit" @reset.prevent="onReset" v-if="show">
      <!-- <fieldset> -->
      <div>
        <input
          class="input"
          id="name"
          v-model="form.name"
          placeholder="Name"
          type="text"
          autocomplete="on"
          required
        />
      </div>
      <div>
        <input
          class="input"
          id="passord"
          v-model="form.password"
          placeholder="Password"
          type="password"
          autocomplete="on"
          required
        />
      </div>
      <!-- </fieldset> -->
      <div>
        <input type="submit" class="input" value="Submit" />
      </div>
      <div v-if="reset">
        <input type="reset" class="input" value="Reset" />
      </div>
    </form>
    <p :style="statusStyle" v-if="responseStatus">
      {{ responseStatus }}
    </p>
    <img :src="image" style="width: 100%; max-width: 300px" />
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import image from "@/assets/images/smali_1.jpg";
export default {
  data() {
    return {
      data: null,
      responseStatus: null,
      form: {
        password: "",
        name: "",
      },
      show: true,
      reset: false,
      hostname: window.location.hostname,
      image: image,
      statusStyle: {
        color: "black",
      },
    };
  },
  methods: {
    ...mapMutations({
      setMqttIsLoginPageUpdated: "mqttStore/setMqttIsLoginPageUpdated",
      setMqttUserName: "mqttStore/setMqttUserName",
      setMqttPassword: "mqttStore/setMqttPassword",
      setMqttRole: "mqttStore/setMqttRole",
    }),
    onSubmit() {
      let url = "https://" + this.hostname + "/nodejs";
      // eslint-disable-next-line
      // console.log("name:"+this.form.name);
      // eslint-disable-next-line
      //console.log("password:"+this.form.password);
      // https://jasonwatmore.com/post/2022/06/09/vue-fetch-http-put-request-examples
      // https://stackoverflow.com/questions/64107197/how-to-enable-the-built-in-password-saving-prompt-in-chrome-with-vue-js-2-boots
      // https://github.com/nativescript-vue/nativescript-vue/issues/326
      // PUT request using fetch with error handling
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: this.form.name,
          password: this.form.password,
        }),
      };
      fetch(url, requestOptions)
        .then(async (response) => {
          const isJson = response.headers
            .get("content-type")
            .includes("application/json");
          this.data = isJson && (await response.json());
          this.statusStyle.color = "red";
          this.responseStatus = "";
          /* Check for error response */
          if (!response.ok) {
            /* Get error message from body or default to response status */
            const error = (this.data && this.data.message) || response.status;
            return Promise.reject(error);
          }
          /* Check all properties */
          let check = true;
          if (!Object.prototype.hasOwnProperty.call(this.data, "status")) {
            check = false;
          }
          if (!Object.prototype.hasOwnProperty.call(this.data, "name")) {
            check = false;
          }
          if (!Object.prototype.hasOwnProperty.call(this.data, "password")) {
            check = false;
          }
          if (!Object.prototype.hasOwnProperty.call(this.data, "mqttRole")) {
            check = false;
          }
          if (false == check) {
            this.responseStatus = "Invalid Response Format";
            return;
          }
          // eslint-disable-next-line
          console.log(JSON.stringify(this.data));
          /* Check the request and response integrity */
          if (this.form.name.localeCompare(this.data.name)) {
            this.responseStatus = "Invalid Name";
            return;
          }
          /* Check the status */
          if (this.data.status.localeCompare("OK")) {
            this.responseStatus = this.data.status;
            return;
          }
          /* Autorized */
          this.statusStyle.color = "white";
          this.responseStatus = "SUCCESS";
          /* -- */
          this.setMqttUserName(this.form.name);
          this.setMqttPassword(this.form.password);
          this.setMqttRole(this.data.mqttRole);
          /* -- */
          this.setMqttIsLoginPageUpdated(1);
        })
        .catch((error) => {
          this.errorMessage = error;
          // eslint-disable-next-line
          console.error("There was an error!", error);
          this.statusStyle.color = "yellow";
          this.responseStatus = "Invalid Request";
        });
    },
    onReset() {
      this.form.name = "";
      this.form.password = "";
    },
  },
};
</script>

<style scoped>
.login {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
  color: white;
  font-size: large;
}
.input {
  color: white;
  background-color: black;
  font-size: large;
  margin: 1vh 0vw 1vh 0vw;
  width: 50%;
  border: 3px solid #303030;
  padding: 10px;
  border-radius: 25px;
  text-align: center;
}
.input:hover {
  border: 3px solid white;
}
.input:focus {
  border: 3px solid white;
  outline: none;
}
</style>
