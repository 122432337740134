<template>
  <div id="container" :style="containerStyle">
    <SensorComponent
      v-for="(properties, index) in sensorProperties"
      :properties="properties"
      :index="index"
      :key="index"
    />
  </div>
</template>

<script>
import SensorComponent from "@/components/Sensor.vue";
import { mapGetters } from "vuex";
import AppConstants from "@/AppConstants.js";
const NUMBER_OF_SENSORS = AppConstants.NUMBER_OF_SENSORS;
export default {
  name: "Panel",
  components: { SensorComponent },
  props: {
    width: Number,
  },
  data: function () {
    return {
      containerStyle: {
        border: "1px solid black",
        width: this.width + "vw",
        background: "black",
      },
      sensorProperties: [],
      sensorPropertiesSize: NUMBER_OF_SENSORS,
    };
  },
  methods: {},
  computed: {
    ...mapGetters({
      getDebugMessage: "infoMsgStore/getDebugMessage",
    }),
  },
  created() {
    /*sensor properties */
    for (let i = 0; i < this.sensorPropertiesSize; i++) {
      this.sensorProperties.push({
        onColor: "red",
        offColor: "blue",
      });
    }
  },
};
</script>
<style scoped></style>
