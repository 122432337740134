<template>
  <div v-if="getMqttIsInstanceConnected" class="content">
    <StripPanelComponent
      :width="stripWidth"
      :height="stripHeight"
      :top="stripTop"
      :mode="stripMode"
    />,
    <PanelComponent
      :width="panelWidth"
      :height="panelHeight"
      :top="panelTop"
      :mode="panelMode"
    />
  </div>
  <div v-else :style="pageStyle">
    {{ connectionMsg }}
  </div>
</template>

<script>
// @ is an alias to /src
import AppConstants from "@/AppConstants.js";
import PanelComponent from "@/components/Panel.vue";
import StripPanelComponent from "@/components/StripPanel.vue";
import { mapGetters } from "vuex";
export default {
  name: "Home",
  data: function () {
    let size = AppConstants.get2Size();
    return {
      stripWidth: size.width,
      stripHeight: size.height,
      stripTop: 0,
      stripMode: 0,
      panelWidth: size.width,
      panelHeight: size.width,
      panelTop: size.height,
      panelMode: 0,
      pageStyle: {
        position: "absolute",
        background: "black",
        border: "3px solid gray",
        left: AppConstants.MENU_WIDTH + "px",
        width: size.width + "vw",
        color: "white",
        fontSize: "x-large",
        margin: "auto",
        textAlign: "center",
        padding: "10px",
      },
    };
  },
  components: {
    StripPanelComponent,
    PanelComponent,
  },
  computed: {
    ...mapGetters({
      getMqttIsInstanceConnected: "mqttStore/getMqttIsInstanceConnected",
    }),
    connectionMsg() {
      return "Waiting for MQTT connection...";
    },
  },
};
</script>
<style scoped>
.conwaiter {
  color: white;
  font-size: x-large;
  margin: auto;
  width: 50%;
  border: 3px solid gray;
  padding: 10px;
  text-align: center;
}
</style>
